import layoutReducer from "@uxf_base/redux/layout/reducer";
import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducer as formReducer } from "redux-form";
import { IReduxState } from "../../types/Redux";

export const initializeStore = (initialState: Partial<IReduxState> = {}): Store<IReduxState> =>
    createStore(
        combineReducers({
            form: formReducer,
            layout: layoutReducer,
        }),
        initialState,
        composeWithDevTools(applyMiddleware()),
    );
