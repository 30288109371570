import { useMeNullable } from "@profile-zone/hooks/use-me-nullable";
import { FlashMessagesService } from "@services/FlashMessagesService";
import { WebPushContext } from "@shared/web-push/context";
import { useWebPushNative } from "@shared/web-push/use-web-push-native";
import { useModal } from "@utils/useModal";
import { Cookie } from "@uxf/core/cookie";
import { isNil } from "@uxf/core/utils/is-nil";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import { ReactNode, useEffect } from "react";
import { WebPushModal } from "./web-push-modal";

const NOTIFICATION_MODAL_COOKIE_NAME = "notification-modal-result";

interface WebPushProviderProps {
    children: ReactNode;
}

export function WebPushProvider(props: WebPushProviderProps) {
    const [toggleModal, Modal, closeModal] = useModal();

    // TODO @vejvis - tohle by tu nemělo být
    const me = useMeNullable();

    const { isReady, subscription, subscribe, unsubscribe } = useWebPushNative();

    const subscribeWithoutModal = () => {
        if (
            typeof Notification === "undefined" ||
            Notification.permission === "granted" ||
            Notification.permission === "default"
        ) {
            subscribe();
        } else if (Notification.permission === "denied") {
            FlashMessagesService.error("Notifikace nejsou povolené ve vašem prohlížeči");
        }
    };

    useEffect(() => {
        if (isNil(me)) {
            return;
        }

        if (!isReady) {
            return;
        }

        if (isNotNil(subscription)) {
            return;
        }

        const cookie = Cookie.create();
        if (cookie.has(NOTIFICATION_MODAL_COOKIE_NAME)) {
            return;
        }

        toggleModal(
            <WebPushModal
                onAccept={() => {
                    cookie.set(NOTIFICATION_MODAL_COOKIE_NAME, "1");
                    subscribe();
                    closeModal();
                }}
                onReject={() => {
                    cookie.set(NOTIFICATION_MODAL_COOKIE_NAME, "0");
                    closeModal();
                }}
            />,
        );
    }, [closeModal, subscribe, toggleModal, unsubscribe, isReady, subscription, me]);

    return (
        <WebPushContext.Provider
            value={{
                isReady,
                isSubscribed: isReady && isNotNil(subscription),
                subscribe: subscribeWithoutModal,
                unsubscribe,
            }}
        >
            {props.children}
            <Modal disableBackdropClose />
        </WebPushContext.Provider>
    );
}
