export const composeRefs = (...args: any[]) => {
    return (ref: any) => {
        args.forEach((arg) => {
            if (!arg) {
                return;
            }

            if (typeof arg === "function") {
                arg(ref);
                return;
            }

            arg.current = ref;
        });
    };
};
