import { ApolloClient } from "@apollo/client/core";
import { ApolloError } from "@apollo/client/errors";
import {
    ClubTokenInvitationAcceptDocument,
    ClubTokenInvitationAcceptMutation,
    ClubTokenInvitationAcceptMutationVariables,
} from "@gql-schema";
import { RedirectToUrl } from "@shared/error/RedirectToUrl";
import { isServer } from "@uxf/core/utils/isServer";
import { queryParamToString } from "@uxf/router";

function removeQueryParam(asPath: string, paramName: string) {
    const url = new URL(asPath, "http://localhost");
    url.searchParams.delete(paramName);

    return url.pathname + url.search + url.hash;
}

async function handleTokenInvitation(ctx: any, client: ApolloClient<any>) {
    if (!isServer) {
        return;
    }

    const token = queryParamToString(ctx.query.invitation_token);

    if (!token) {
        return;
    }

    try {
        await client.mutate<ClubTokenInvitationAcceptMutation, ClubTokenInvitationAcceptMutationVariables>({
            mutation: ClubTokenInvitationAcceptDocument,
            variables: { token },
        });
    } catch (e) {
        if (e instanceof ApolloError) {
            // do nothing
        } else {
            throw e;
        }
    }
    // TODO use getRouteFromUrl after implementing in router
    const urlWithoutToken = removeQueryParam(ctx.asPath ?? "", "invitation_token");

    throw new RedirectToUrl(urlWithoutToken);
}

export const TokenInvitationHelper = {
    handleTokenInvitation,
};
