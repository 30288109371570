import rem from "polished/lib/helpers/rem";
import { PureComponent, ReactNode } from "react";
import { FlashMessage } from "./FlashMessage";
import { Wrapper } from "./styles";
import { FlashMessagesState, Notification } from "./types";

export class FlashMessages extends PureComponent<Record<string, any>, FlashMessagesState> {
    constructor(props: Record<string, any>) {
        super(props);
        this.state = {
            notifications: [],
            windowInnerHeight: "100vh",
        };
    }

    public componentDidMount(): void {
        this.handleWindowInnerHeight();
        window.addEventListener("resize", this.handleWindowInnerHeight);
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.handleWindowInnerHeight);
    }

    private handleWindowInnerHeight = (): void => {
        /* TODO add some debounce method */
        this.setState({ windowInnerHeight: rem(window.innerHeight) });
    };

    public open = (notification: Notification) => {
        this.setState((state) => ({
            notifications: [
                ...state.notifications,
                {
                    ...notification,
                    variant: notification.variant || "text",
                    id: notification.id || Math.round(Math.random() * 1000),
                },
            ],
        }));
    };

    public openPermanent = (PermanentComponent: ReactNode) => {
        this.setState({
            PermanentComponent,
        });
    };

    public closePermanent = () => {
        this.setState({
            PermanentComponent: undefined,
        });
    };

    public close = (notification: any) => {
        this.setState((state) => ({
            notifications: state.notifications.filter((n) => n !== notification),
        }));
    };

    public render() {
        const { notifications, PermanentComponent, windowInnerHeight } = this.state;
        return (
            <Wrapper role="list" style={{ height: windowInnerHeight }}>
                {notifications.map((notification) => (
                    <FlashMessage
                        key={`notification${notification.id}`}
                        notification={notification}
                        onClose={() => this.close(notification)}
                    />
                ))}
                {PermanentComponent && PermanentComponent}
            </Wrapper>
        );
    }
}
