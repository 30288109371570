import Bowser from "bowser";
import { createContext, useContext } from "react";

export const UAParserContext = createContext<Bowser.Parser.ParsedResult | undefined>(undefined);

export const useUAParserContext = () => useContext(UAParserContext);

export const useIsMobile = () => useUAParserContext()?.platform.type === "mobile";
export const useIsMobileApple = () => {
    const platform = useUAParserContext()?.platform;
    return platform?.type === "mobile" && platform?.vendor === "Apple";
};
export const useIsMobileAndroid = () => {
    const platform = useUAParserContext()?.platform;
    const os = useUAParserContext()?.os;
    return platform?.type === "mobile" && (platform?.vendor === "Android" || os?.name === "Android");
};
