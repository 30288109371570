import { ICONS, ICONS_VERSION } from "@config/icons";
import { ICON_SPRITE } from "@config/icons-config";
import { Palette } from "@styles/theme";
import { ForwardRefRenderFunction, forwardRef, memo } from "react";
import { useTheme } from "styled-components";
import { Svg } from "./styles";
import { IIconProps } from "./types";

const IconComponent: ForwardRefRenderFunction<SVGSVGElement, IIconProps> = (props, ref) => {
    const theme = useTheme();
    const { component, color = "currentColor", cover, name, size, ...restProps } = props;

    if (name) {
        const svgDefinition: readonly number[] = ICONS[name];

        if (!svgDefinition) {
            // tslint:disable-next-line:no-console
            console.warn(`Icon "${name}" not found.`); // eslint-disable-line no-console
            return null;
        } else if (!svgDefinition.includes(size)) {
            // tslint:disable-next-line:no-console
            console.warn(`Icon "${name}" in size "${size}" not found.`); // eslint-disable-line no-console
            return null;
        }

        return (
            <Svg
                height={size}
                preserveAspectRatio={`xMidYMid ${cover ? "slice" : "meet"}`}
                ref={ref}
                role="img"
                viewBox={`0 0 ${size} ${size}`}
                width={size}
                color={theme.color.palette[color as Palette] ? theme.color.palette[color as Palette] : color}
                {...restProps}
            >
                <use xlinkHref={`${ICON_SPRITE}?v=${ICONS_VERSION}#icon-sprite--${name}_${size}`} />
            </Svg>
        );
    }

    return (
        <Svg
            as={component}
            $height={size}
            $width={size}
            preserveAspectRatio={`xMidYMid ${cover ? "slice" : "meet"}`}
            ref={ref}
            role="img"
            {...restProps}
        />
    );
};

export const Icon = memo(forwardRef(IconComponent));

Icon.displayName = "Icon";
