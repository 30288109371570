import * as moment from "moment";
import "moment/locale/cs";

import { translations } from "@translations/translations";
import { PluralResolverMap, TranslationService as UxfTranslationService } from "@uxf/translations";

export enum SupportedLanguages {
    Cs = "cs",
    En = "en",
}

export type Translations = typeof translations;
export type TranslationStrings = keyof Translations;

/*
 * 1    one
 * 2    few
 * 3    few
 * 4    other
 * 5    other
 * 6    other
 * 100  other
 */

moment.locale("cs");

const pluralResolverMap: PluralResolverMap = {
    [SupportedLanguages.Cs]: (count) => {
        switch (true) {
            case count === 0:
                return "zero";
            case count === 1:
                return "one";
            case count >= 2 && count <= 4:
                return "few";
            default:
                return "other";
        }
    },
    [SupportedLanguages.En]: (count) => (count === 1 ? "one" : "other"),
};

export const TranslationService = new UxfTranslationService<TranslationStrings, SupportedLanguages>(
    translations,
    SupportedLanguages.Cs,
    pluralResolverMap,
);

export const t = TranslationService.translate;
