import transparentize from "polished/lib/color/transparentize";
import { css, FlattenSimpleInterpolation } from "styled-components";

export const buttonReset = css`
    appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 0;
`;

export const elementFocus = (color: string): FlattenSimpleInterpolation => css`
    box-shadow: 0 0 0 0.25rem ${transparentize(0.5, color)};
    outline: 0;
`;

export const srOnly = css`
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const noWrap = css`
    white-space: nowrap;
`;

export const inputReset = css`
    ${buttonReset};

    /* stylelint-disable property-no-vendor-prefix, no-descending-specificity */
    &:invalid {
        box-shadow: none;
    }

    &::-moz-placeholder {
        opacity: 1;
    }

    &::-ms-clear,
    &::-ms-reveal {
        display: none;
    }

    &::-webkit-calendar-picker-indicator,
    &::-webkit-clear-button,
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type="date"],
    &[type="datetime"],
    &[type="datetime-local"],
    &[type="month"],
    &[type="number"],
    &[type="time"],
    &[type="week"] {
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }
    /* stylelint-enable */
`;

export const selectReset = (fontFamily: string): FlattenSimpleInterpolation => css`
    ${buttonReset};

    /* stylelint-disable property-no-vendor-prefix, no-descending-specificity */
    &:invalid {
        box-shadow: none;
    }

    &::-ms-expand {
        display: none;
    }
    /* stylelint-enable */

    option {
        font-family: ${fontFamily};
        font-size: 0.8rem;
        font-weight: 400;
    }
`;

export const textareaReset = css`
    ${buttonReset};

    /* stylelint-disable property-no-vendor-prefix, no-descending-specificity */
    &:invalid {
        box-shadow: none;
    }

    &::-moz-placeholder {
        opacity: 1;
    }
    /* stylelint-enable */
`;

export const inputAutofill = (background: string, color: string): FlattenSimpleInterpolation => css`
    /* stylelint-disable property-no-vendor-prefix, no-descending-specificity */
    &:-webkit-autofill {
        -webkit-text-fill-color: ${color} !important;
        -webkit-box-shadow: 0 0 0 1000px ${background} inset;
    }
    &:-moz-autofill {
        -moz-text-fill-color: ${color} !important;
        -moz-box-shadow: 0 0 0 1000px ${background} inset;
    }
    &:-o-autofill {
        -o-text-fill-color: ${color} !important;
        -o-box-shadow: 0 0 0 1000px ${background} inset;
    }
    &:-khtml-autofill {
        -khtml-text-fill-color: ${color} !important;
        -khtml-box-shadow: 0 0 0 1000px ${background} inset;
    }
    /* stylelint-enable */
`;
