import { RouteName } from "@app-routes";
import ExtendableError from "es6-error";
import { RouteList } from "../../../routes/routes";

export class RedirectError extends ExtendableError {
    private _route: RouteName;
    private _params: any;

    constructor(route: RouteName, params: RouteList[typeof route]) {
        super("Redirect error");
        this._route = route;
        this._params = params;
    }

    get route(): RouteName {
        return this._route;
    }

    get params(): any {
        return this._params;
    }
}
