import { IconSizeEnum } from "@config/icons";
import { withUnit } from "@uxf_base/utils/styling";
import shade from "polished/lib/color/shade";
import tint from "polished/lib/color/tint";
import transparentize from "polished/lib/color/transparentize";
import rem from "polished/lib/helpers/rem";

export const BORDER_RADIUS = {
    default: 4,
    squircle: 8,
    message: 16,
    full: 999,
    inherit: "inherit",
};

export const BORDER_WIDTH = {
    default: 1,
    thick: 2,
};

export const BREAKPOINT = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
};

export const BUTTON_HEIGHT = {
    tiny: 24,
    small: 32,
    medium: 40,
    large: 48,
    huge: 56,
    giant: 64,
};

export const TEXT_BADGE_HEIGHT = {
    small: 18,
    large: 24,
};

export const CHECKBOX_SIZE = {
    default: 28,
    small: 20,
};

export const RADIO_SIZE = {
    default: 28,
    small: 20,
};

export const CONTAINER_WIDTH = {
    xs: null,
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1020,
    xxl: 1248,
};

export const ICON_SIZE = IconSizeEnum;

export const CIRCLE_SIZE = {
    size20: {
        radius: 20,
        icon: ICON_SIZE.small,
    },
    size24: {
        radius: 24,
        icon: ICON_SIZE.small,
    },
    size32: {
        radius: 32,
        icon: ICON_SIZE.small,
    },
    size36: {
        radius: 36,
        icon: ICON_SIZE.small,
    },
    size40: {
        radius: 40,
        icon: ICON_SIZE.medium,
    },
    size48: {
        radius: 48,
        icon: ICON_SIZE.medium,
    },
    size56: {
        radius: 56,
        icon: ICON_SIZE.large,
    },
    size70: {
        radius: 70,
        icon: ICON_SIZE.large,
    },
    size80: {
        radius: 80,
        icon: ICON_SIZE.large,
    },
    size110: {
        radius: 110,
        icon: ICON_SIZE.large,
    },
};

export const INPUT_HEIGHT = {
    small: 32,
    default: 40,
    medium: 48,
    large: 56,
};

export const LIST_HEIGHT = {
    small: 40,
    medium: 48,
    large: 56,
    huge: 64,
};

export const MODAL_WIDTH = {
    alert: 270,
    small: 370,
    medium: 420,
    wide: 480,
    extraWide: 640,
};

export const ROOT_FONT_SIZE = 16;

const TYPOGRAPHY_VARIANT = {
    hero: {
        fontSize: 52,
        lineHeight: 68,
    },
    h1: {
        fontSize: 40,
        lineHeight: 52,
    },
    h2: {
        fontSize: 32,
        lineHeight: 42,
    },
    h3: {
        fontSize: 28,
        lineHeight: 36,
    },
    h4: {
        fontSize: 22,
        lineHeight: 30,
    },
    h5: {
        fontSize: 16,
        lineHeight: 22,
    },
    large: {
        fontSize: 16,
        lineHeight: 24,
    },
    medium: {
        fontSize: 14,
        lineHeight: 20,
    },
    small: {
        fontSize: 12,
        lineHeight: 20,
    },
    caption: {
        fontSize: 12,
        lineHeight: 18,
    },
    tiny: {
        fontSize: 10,
        lineHeight: 14,
    },
};

const PALETTE = {
    bg: "#f5f5f5",
    bgLight: "#f9f9f9",
    black: "#000000",
    border: "#ebebeb",
    brown: "#82655a",
    brownLight: tint(0.95, "#82655a"),
    danger: "#ff6161",
    dangerLight: tint(0.85, "#ff6161"),
    facebookBlue: "#1877f2",
    inputBorder: "#dbdbdb",
    orange: "#ef7900",
    orangeLight: tint(0.9, "#ef7900"),
    primary: "#33d2b3",
    primaryBg: tint(0.95, "#33d2b3"),
    primaryLight: tint(0.85, "#33d2b3"),
    purple: "#846cae",
    secondary: "#2a7de1",
    secondaryBg: transparentize(0.95, "#2a7de1"),
    secondaryLight: tint(0.85, "#2a7de1"),
    tertiary: "#ffdd00",
    tertiaryLight: tint(0.85, "#ffdd00"),
    text: "#3c3c3b",
    textMuted: "#888887",
    textMutedLighter: "#C3C3C3",
    transparent: "transparent",
    twitterBlue: "#1D9BF0",
    white: "#ffffff",
};

const THEME = {
    border: BORDER_WIDTH,
    borderRadius: BORDER_RADIUS,
    breakpoint: BREAKPOINT,
    color: {
        backdrop: {
            default: transparentize(0.5, PALETTE.text),
            dark: transparentize(0.25, PALETTE.text),
        },
        palette: PALETTE,
        button: {
            default: {
                background: (color: BasicColorVariant) => !!color && [PALETTE[color], shade(0.15, PALETTE[color])],
                text: PALETTE.white,
            },
            muted: {
                background: (color: BasicColorVariant) =>
                    !!color && [tint(0.85, PALETTE[color]), tint(0.7, PALETTE[color])],
                text: (color: BasicColorVariant) => !!color && PALETTE[color],
            },
            text: {
                background: "transparent",
                text: (color: BasicColorVariant) => !!color && [PALETTE[color], tint(0.4, PALETTE[color])],
            },
            white: {
                text: (color: BasicColorVariant) => !!color && [PALETTE[color], tint(0.4, PALETTE[color])],
            },
            transparent: {
                background: ["transparent", transparentize(0.8, PALETTE.white)],
                text: [PALETTE.white, tint(0.4, PALETTE.white)],
            },
            outline: {
                background: (color: BasicColorVariant) =>
                    !!color && ["transparent", transparentize(0.9, PALETTE[color])],
                text: (color: BasicColorVariant) => !!color && [PALETTE[color], shade(0.15, PALETTE[color])],
            },
        },
        circle: {
            default: {
                background: (color: BasicColorVariant | "white") =>
                    !!color && [PALETTE[color], shade(0.15, PALETTE[color])],
                text: (color: BasicColorVariant | "white") => (color === "white" ? PALETTE.text : PALETTE.white),
            },
            light: {
                background: (color: BasicColorVariant | "white") =>
                    !!color && [tint(0.9, PALETTE[color]), tint(0.7, PALETTE[color])],
                text: (color: BasicColorVariant | "white") => !!color && PALETTE[color],
            },
        },
        ghostButton: {
            background: (light = false) => ["transparent", light ? transparentize(0.9, PALETTE.bg) : PALETTE.bg],
            text: (color?: BasicColorVariant, light = false) =>
                light ? PALETTE.white : color ? PALETTE[color] : PALETTE.textMuted,
        },
    },
    container: CONTAINER_WIDTH,
    size: {
        badge: 18,
        button: BUTTON_HEIGHT,
        textBadge: TEXT_BADGE_HEIGHT,
        checkbox: CHECKBOX_SIZE,
        radio: RADIO_SIZE,
        circle: CIRCLE_SIZE,
        dropdown: 40,
        icon: ICON_SIZE,
        redirectBar: 64,
        infoBar: 48,
        input: INPUT_HEIGHT,
        ghostButton: {
            radius: 36,
            icon: ICON_SIZE.large,
        },
        footer: 200,
        list: LIST_HEIGHT,
        modal: MODAL_WIDTH,
        registration: 436,
        toolbar: 72,
    },
    shadow: {
        avatar: `0 ${rem(10)} ${rem(30)} ${rem(-5)} rgba(20,117,98,0.35)`,
        badge: (color: BasicColorVariant) =>
            !!color && `0 ${rem(5)} ${rem(15)} ${rem(-2)} ${transparentize(0.75, PALETTE[color])}`,
        circle: `0 ${rem(10)} ${rem(10)} ${rem(-5)} ${transparentize(0.75, PALETTE.primary)}`,
        button: (color: BasicColorVariant) =>
            !!color && `0 ${rem(10)} ${rem(20)} ${rem(-5)} ${transparentize(0.75, PALETTE[color])}`,
        buttonFocus: `0 0 0 ${rem(8)} ${transparentize(0.9, PALETTE.secondary)}`,
        dropdown: `0 ${rem(3)} ${rem(12)} ${rem(-2)} ${transparentize(0.65, PALETTE.text)}`,
        footer: `0 ${rem(-5)} ${rem(20)} ${rem(-2)} ${transparentize(0.65, PALETTE.text)}`,
        glow: `0 ${rem(10)} ${rem(20)} 0 ${transparentize(0.75, PALETTE.text)}`,
        modal: `0 ${rem(5)} ${rem(15)} ${rem(-2)} ${transparentize(0.65, PALETTE.text)}`,
        switch: `0 ${rem(2)} ${rem(5)} 0 ${transparentize(0.65, PALETTE.text)}`,
        iPhone: `${rem(15)} ${rem(15)} ${rem(20)} 0 ${transparentize(0.8, PALETTE.text)}`,
    },
    spacing: {
        pageTop: 32,
        innerHorizontal: 16,
        outerHorizontal: 24,
        outerVertical: 12,
        innerSmallVertical: 12,
        innerLargeVertical: 24,
        pageHorizontal: 16,
        smallHorizontal: 16,
        largeHorizontal: 24,
    },
    typography: {
        fontFamily: "var(--default-font-family)",
        letterSpacing: withUnit(-0.021, "em"),
        fontWeight: {
            regular: 400,
            medium: 500,
            semiBold: 600,
            bold: 700,
        },
        rootFontSize: ROOT_FONT_SIZE,
        variant: TYPOGRAPHY_VARIANT,
    },
    zIndex: {
        focus: 5,
        fixed: 10,
        navBar: 30,
        modal: 100,
        dropdown: 200,
        flashMessage: 250,
        tooltip: 300,
    },
    /** @deprecated Please use appropriate definition in `theme.spacing` or some custom exact value. */
    legacyGutter: (factor: number) => 10 * factor,
    /** @deprecated Please replace with `import { spacing } from "@uxf/styles/units/spacing"` or use appropriate definition in `theme.spacing` or some custom exact value. */
    legacySpacing: (factor: number) => 8 * factor,
};

export type ITheme = typeof THEME;

export const theme: ITheme = THEME;

export type ThemeProp = keyof typeof theme;

export type Breakpoint = keyof typeof theme.breakpoint;
export type BorderSize = keyof typeof theme.border;
export type ButtonHeight = keyof typeof theme.size.button;
export type ButtonKind = keyof typeof theme.color.button;
export type TextBadgeKind = Omit<ButtonKind, "text">;
export type TextBadgeHeight = keyof typeof theme.size.textBadge;
export type CheckboxSize = keyof typeof theme.size.checkbox;
export type RadioSize = keyof typeof theme.size.radio;
export type CircleSize = keyof typeof theme.size.circle;
export type FontWeight = "inherit" | number | keyof typeof theme.typography.fontWeight;
export type IconSize = keyof typeof theme.size.icon;
export type InputSize = keyof typeof theme.size.input;
export type LetterSpacing = "inherit" | number | typeof theme.typography.letterSpacing;
export type ListHeight = keyof typeof theme.size.list;
export type ModalWidth = number | keyof typeof theme.size.modal;
export type Palette = keyof typeof theme.color.palette;
export type BasicColorVariant =
    | "textMutedLighter"
    | "black"
    | "brown"
    | "danger"
    | "facebookBlue"
    | "orange"
    | "primary"
    | "secondary"
    | "tertiary"
    | "text"
    | "textMuted"
    | "twitterBlue"
    | "white";
export type AccentColorVariant = "orange" | "purple" | "brown";
export type TextAlign = "left" | "right" | "center" | "justify" | "inherit";
export type TextVariant = "inherit" | number | keyof typeof theme.typography.variant;
export type TextVariantProperty = keyof typeof theme.typography.variant.h1;
