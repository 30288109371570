import { DEFAULT_BOX_PADDING } from "@styles/constants";
import { Box } from "@ui/Box";
import { spacing } from "@uxf/styles/units/spacing";
import { Children, FC } from "react";
import { Content, Footer, Root } from "./styles";
import { IModalContentProps } from "./types";

export const ModalContent: FC<IModalContentProps> = (props) => {
    const { buttons, children, contentProps, header, ...restProps } = props;
    const hasButtons = !!buttons && Children.toArray(buttons).length > 0;
    return (
        <Root {...restProps}>
            <Content pb={spacing(2)} pt={spacing(2)} {...contentProps}>
                {!!header && (
                    <Box as="header" pl={DEFAULT_BOX_PADDING} pr={DEFAULT_BOX_PADDING}>
                        {header}
                    </Box>
                )}
                {children}
            </Content>
            {hasButtons && <Footer>{buttons}</Footer>}
        </Root>
    );
};
