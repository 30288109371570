import { margins } from "@styles/mixins";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import styled, { css } from "styled-components";
import { ISvgProps } from "./types";

export const Svg = styled("svg")<ISvgProps>(
    ({ iconHeight, iconWidth, mb, ml, mr, mt, theme }) => css`
        ${Responsive.facePaintedMin({
            height: CssHelper.value(iconHeight),
            width: CssHelper.value(iconWidth),
        })}
        ${margins({ mb, ml, mr, mt }, theme)};
    `,
);
