import { Box } from "@ui/Box";
import { TextCaption, TextMedium } from "@ui/Text";
import { PureComponent } from "react";
import { Root, StyledButton } from "./styles";
import { FlashMessageProps } from "./types";

export class FlashMessage extends PureComponent<FlashMessageProps> {
    constructor(props: FlashMessageProps) {
        super(props);
    }

    public componentDidMount(): void {
        const { autoDismiss = true, dismissTimeout = 5000 } = this.props.notification;
        if (autoDismiss) {
            setTimeout(this.close, dismissTimeout);
        }
    }

    private close = () => {
        const { onClose } = this.props;
        onClose();
    };

    private handleClick = () => {
        this.close();
    };

    public render() {
        const { notification } = this.props;
        return (
            <Root onClick={this.handleClick} role="listitem" variant={notification.variant}>
                <Box flexShrink="1" flexGrow="1" flexBasis="auto" pb={16} pl={16} pr={16} pt={16}>
                    <TextMedium textFontWeight="medium" maxLines={7}>
                        {notification.title}
                    </TextMedium>
                    {!!notification.body && (
                        <TextCaption
                            maxLines={3}
                            mt={4}
                            textColor={notification.variant === "text" ? "textMuted" : null}
                        >
                            {notification.body}
                        </TextCaption>
                    )}
                </Box>
                {!!notification.button && (
                    <StyledButton
                        pl={16}
                        pr={16}
                        variant={notification.variant === "text" ? "secondary" : notification.variant}
                        {...notification.buttonProps}
                    >
                        {notification.button}
                    </StyledButton>
                )}
            </Root>
        );
    }
}
