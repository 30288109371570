import { FieldMergeFunction } from "@apollo/client/cache/inmemory/policies";

const cursorPagination: FieldMergeFunction<any, any> | boolean = (existing, incoming, { args }) => {
    if (args && args.cursor) {
        return [...existing, ...incoming];
    }
    return incoming;
};

export default cursorPagination;
