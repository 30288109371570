import { RouteName, routeToUrl } from "@app-routes";
import { config } from "@config/config";
import { FileFragment } from "@gql-schema";
import { AppInstance } from "@shared/utils/app-instance";
import { isServer } from "@uxf_base/utils/isServer";
import { NextPageContext } from "next";
import Router from "next/router";
import { RouteList } from "../routes/routes";

type GQLFile = FileFragment;

enum ImageUrlTypeEnum {
    "crop" = "c",
    "thumbnail" = "t",
}

enum ResizerTypeEnum {
    "crop" = "cv",
    "thumbnail" = "cn",
}

const getImageUrl = (
    file?: GQLFile | null,
    width = 200,
    height = 300,
    type: keyof typeof ImageUrlTypeEnum = "crop",
): string | null => {
    if (!file || !file.uuid) {
        return null;
    }

    const ch1 = file.uuid.charAt(0);
    const ch2 = file.uuid.charAt(1);

    const fit = ResizerTypeEnum[type];
    const position = "c";
    const background = "t";
    const trim = "nt";
    const ext = file.extension;
    const toFormat = "png";
    const namespace = file.namespace || "front";

    return `/generated/${namespace}/${ch1}/${ch2}/${file.uuid}_${width}_${height}_${fit}_${position}_${background}_${trim}_x_${ext}.${toFormat}`;
};

const getFileUrl = (file: GQLFile): string => {
    const ch1 = file.uuid.charAt(0);
    const ch2 = file.uuid.charAt(1);
    const ns = file.namespace ?? "front";

    return `/upload/${ns}/${ch1}/${ch2}/${file.uuid}.${file.extension}`;
};

const getMessengerFileUrl = (file: GQLFile): string => {
    const ch1 = file.uuid.charAt(0);
    const ch2 = file.uuid.charAt(1);

    return `/upload/messenger/${ch1}/${ch2}/${file.uuid}.${file.extension}`;
};

const getTermsAndConditionsUrl = (): string => {
    return AppInstance.isSlovak
        ? routeToUrl("footer-zone/terms-and-conditions-sk")
        : routeToUrl("footer-zone/terms-and-conditions");
};

// TODO route params generics
const redirectRoute = async <Route extends RouteName>(
    ctx: NextPageContext,
    ...args: RouteList[Route] extends null ? [Route] : [Route, RouteList[Route]]
): Promise<void> => {
    const [route, params] = args;
    if (isServer) {
        const redirectUrl: string = routeToUrl(route as any, params as any);

        if (!ctx.res?.headersSent) {
            // eslint-disable-next-line no-unused-expressions
            ctx.res?.writeHead(302, { Location: redirectUrl });
            // eslint-disable-next-line no-unused-expressions
            ctx.res?.end();
        }
    } else {
        await Router.push(routeToUrl(route as any, params as any));
    }
};

const redirectUrl = async (ctx: NextPageContext, url: string): Promise<void> => {
    if (isServer) {
        if (!ctx.res?.headersSent) {
            // eslint-disable-next-line no-unused-expressions
            ctx.res?.writeHead(302, { Location: url });
            // eslint-disable-next-line no-unused-expressions
            ctx.res?.end();
        }
    } else {
        await Router.push(url);
    }
};

export const getAbsoluteUrl = (route: RouteName, params: RouteList[typeof route] = {}): string => {
    return config.FRONTEND_URL + routeToUrl(route as any, (params ?? {}) as any);
};

export const UrlHelper = {
    getImageUrl,
    getFileUrl,
    redirectRoute,
    redirectUrl,
    getAbsoluteUrl,
    getMessengerFileUrl,
    getTermsAndConditionsUrl,
};
