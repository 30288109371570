import { ApolloError } from "@apollo/client/errors";
import * as Sentry from "@sentry/nextjs";
import { t } from "@services/TranslationService";
import { FlashMessagesService } from "./FlashMessagesService";

const getGraphQLErrorMessage = (e: ApolloError): string => {
    if (e.networkError) {
        return "Chyba s připojením";
    }
    return t(e.graphQLErrors[0].message as any);
};

const getErrorMessage = (e: any, returnUnknownErrorMessage = true): string | null => {
    if (e instanceof ApolloError) {
        return getGraphQLErrorMessage(e);
    }

    if (returnUnknownErrorMessage) {
        return t("error.unknown");
    }

    return null;
};

const logError = (e: any): void => {
    if (process.env.SENTRY_DSN) {
        Sentry.captureException(e);
    } else {
        console.warn("Error handler: ", e); // eslint-disable-line no-console
    }
};

const handleError = (e: any): void => {
    const errorMessage = getErrorMessage(e, false);

    FlashMessagesService.flashMessage({
        title: errorMessage || t("error.unknown"),
        variant: "danger",
        dismissTimeout: 15000,
    });

    if (!errorMessage) {
        logError(e);
    }
};

export const ErrorService = {
    logError,
    getErrorMessage,
    handleError,
};
