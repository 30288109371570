import { config } from "@config/config";
import { useWebPushRegisterMutation } from "@gql-schema";
import { ErrorService } from "@services/ErrorService";
import { FlashMessagesService } from "@services/FlashMessagesService";
import { useTranslation } from "@translations/use-translation";
import { isNil } from "@uxf/core/utils/is-nil";
import { useCallback, useEffect, useState } from "react";

function base64ToUint8Array(base64: string) {
    const padding = "=".repeat((4 - (base64.length % 4)) % 4);
    const b64 = (base64 + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = self.atob(b64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export function useWebPushNative() {
    const { t } = useTranslation();
    const [isReady, setIsReady] = useState(false);
    const [subscription, setSubscription] = useState<PushSubscription | null>(null);
    const [registration, setRegistration] = useState<ServiceWorkerRegistration | null>(null);

    const [registerWebPush] = useWebPushRegisterMutation();

    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.ready
                .then((reg) => {
                    setRegistration(reg);
                    return reg.pushManager.getSubscription().then((sub) => {
                        if (sub && !(sub.expirationTime && Date.now() > sub.expirationTime - 5 * 60 * 1000)) {
                            setSubscription(sub);
                        }
                    });
                })
                .finally(() => setIsReady(true))
                .catch(ErrorService.logError);
        }
    }, []);

    const subscriptionString = JSON.stringify(subscription);
    useEffect(() => {
        const sub = JSON.parse(subscriptionString);
        if (isNil(sub)) {
            return;
        }

        registerWebPush({
            variables: { subscription: sub },
        }).catch(ErrorService.logError);
    }, [registerWebPush, subscriptionString]);

    const subscribe = useCallback(() => {
        if (isNil(registration)) {
            return ErrorService.logError("Service worker registration not ready");
        }

        const serverKey = config.WEB_PUSH_PUBLIC_KEY;
        if (!serverKey) {
            return ErrorService.logError("WEB_PUSH_PUBLIC_KEY is not defined");
        }

        registration.pushManager
            .subscribe({
                userVisibleOnly: true,
                applicationServerKey: base64ToUint8Array(serverKey),
            })
            .then((result: PushSubscription) => {
                setSubscription(result);
                // TODO @vejvis - tohle by nemělo být v tomhle univerzálním hooku
                FlashMessagesService.successMessages(t("web-push:notification-subscribed"));
            })
            .catch((e) => {
                ErrorService.logError(e);
                FlashMessagesService.error(t("web-push:notification-subscription-failed"));
            });
    }, [registration, t]);

    const unsubscribe = useCallback(() => {
        subscription
            ?.unsubscribe()
            .then(() => {
                setSubscription(null);
                // TODO @vejvis - tohle by nemělo být v tomhle univerzálním hooku
                FlashMessagesService.successMessages(t("web-push:notification-unsubscribed"));
            })
            .catch(ErrorService.handleError);
    }, [subscription, t]);

    return {
        subscription,
        // TODO @vejvis - na iosu to nefunguje pokud nejsi v pwa
        isReady: isReady && registration?.pushManager?.subscribe !== undefined,
        subscribe,
        unsubscribe,
    };
}
