import { Button, IButtonProps } from "@ui/Button";
import { CssHelper } from "@utils/CssHelper";
import { mediaMin } from "@uxf_base/utils/styling";
import shade from "polished/lib/color/shade";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { RootProps } from "./types";

export const Wrapper = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: flex-end;
        left: 0;
        overflow: hidden;
        padding: ${rem(16)};
        pointer-events: none;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: ${theme.zIndex.flashMessage};

        ${mediaMin(theme.breakpoint.md)} {
            padding: ${rem(20)};
        }
    `,
);

export const Root = styled("div")<RootProps>(
    ({ variant = "text", theme }) => css`
        background-color: ${variant !== "text" ? shade(0.1, theme.color.palette[variant]) : theme.color.palette.white};
        border-radius: ${rem(theme.borderRadius.default)};
        box-shadow: ${theme.shadow.button(variant)};
        color: ${variant !== "text" ? theme.color.palette.white : theme.color.palette.text};
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        max-width: ${rem(292)};
        pointer-events: auto;
        transition: ${CssHelper.transition("backgroundColor")};
        width: 100%;

        &:not([data-permanent="true"]):hover,
        &:not([data-permanent="true"]):active {
            background-color: ${variant !== "text"
                ? shade(0.25, theme.color.palette[variant])
                : shade(0.05, theme.color.palette.white)};
        }

        & + * {
            margin-top: ${rem(theme.legacySpacing(1))};

            ${mediaMin(theme.breakpoint.md)} {
                margin-top: ${rem(theme.legacySpacing(1.5))};
            }
        }
    `,
);

export const StyledButton = styled(Button)<IButtonProps>`
    &&& {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        display: block;
        width: 100%;
    }
`;
