import { NormalizedCacheObject } from "@apollo/client/cache";
import { ApolloClient } from "@apollo/client/core/ApolloClient";
import { getDisplayName } from "@apollo/client/react/hoc/hoc-utils";
import { getDataFromTree } from "@apollo/client/react/ssr";
import { isBrowser } from "@uxf_base/utils/isBrowser";
import { ServerResponse } from "http";
import { AppContextType, AppInitialProps } from "next/dist/shared/lib/utils";
import Router from "next/router";
import { Component } from "react";
import { routeToUrl } from "../routes";
import initApollo from "./initApollo";

const parseUtms = (redirUrl: string): Record<string, string> => {
    const utms: Record<string, string> = {};
    const queryString = redirUrl.includes("?") ? redirUrl.replace(/^.*?\?/g, "") : "";
    const query = new URLSearchParams(queryString);
    for (const [key, value] of Array.from(query.entries())) {
        if (key.startsWith("utm_")) {
            utms[key] = value;
        }
    }
    return utms;
};

const redirectToLogin = (res?: ServerResponse, redirUrl?: string, toRegistration = false): void => {
    const path = toRegistration ? "/registrace" : "/login";
    const href = toRegistration
        ? routeToUrl("auth-zone/registration", { redirUrl })
        : routeToUrl("auth-zone/login", { redirUrl });
    if (redirUrl && redirUrl.startsWith(path)) {
        return;
    }
    if (res) {
        if (!res.headersSent) {
            let location = path;
            if (redirUrl) {
                const utms = parseUtms(redirUrl);
                location += "?" + new URLSearchParams({ redirUrl, ...utms }).toString();
            }
            res.writeHead(302, {
                Location: location,
            });
            res.end();
        }
    } else if (isBrowser) {
        Router.push(href);
    }
};

const withApollo = (PageComponent: any) => {
    return class extends Component {
        public static displayName = `withApollo(${getDisplayName(PageComponent)})`;
        private readonly apolloClient: ApolloClient<NormalizedCacheObject>;

        public static async getInitialProps(appCtx: AppContextType) {
            const {
                AppTree,
                ctx: { req, res, query },
            } = appCtx;

            // Run all GraphQL queries in the component tree
            // and extract the resulting data
            const apolloClient = initApollo(
                {},
                {
                    redirectToLogin: () => redirectToLogin(res, req?.url, !!query.registration),
                    req,
                },
            );

            (appCtx.ctx as any).apolloClient = apolloClient;

            let appProps: AppInitialProps = {
                pageProps: {},
            };
            if (PageComponent.getInitialProps) {
                appProps = await PageComponent.getInitialProps(appCtx);
            }

            if (res && res.finished) {
                // When redirecting, the response is finished.
                // No point in continuing to render
                return {};
            }

            if (!isBrowser) {
                try {
                    // Run all GraphQL queries
                    await getDataFromTree(<AppTree {...appProps} apolloClient={apolloClient} />);
                } catch (error) {
                    // Prevent Apollo Client GraphQL errors from crashing SSR.
                    // Handle them in components via the data.error prop:
                    // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
                    console.error("Error while running `getDataFromTree`", error); // eslint-disable-line no-console
                }
            }

            // Extract query data from the Apollo store
            const apolloState: NormalizedCacheObject = apolloClient.cache.extract();
            return {
                ...appProps,
                apolloState,
            };
        }

        public constructor(props: any) {
            super(props);
            // `getDataFromTree` renders the component first, the client is passed off as a property.
            // After that rendering is done using Next's normal rendering pipeline

            this.apolloClient = initApollo(props.apolloState, {
                redirectToLogin: () =>
                    redirectToLogin(undefined, props.router.asPath, !!props.router.query?.registration),
            });
        }

        public render() {
            return <PageComponent apolloClient={this.apolloClient} {...this.props} />;
        }
    };
};

export default withApollo;
