// this file is generated automatically, do not change anything manually in the contents of this file

export const ICONS = {
    "arrow-left": { w: 448, h: 512 },
    "arrow-right": { w: 448, h: 512 },
    "calendar": { w: 448, h: 512 },
    "camera": { w: 512, h: 512 },
    "caretDown": { w: 320, h: 512 },
    "clock": { w: 512, h: 512 },
    "bars": { w: 448, h: 512 },
    "check": { w: 512, h: 512 },
    "chevronDown": { w: 512, h: 512 },
    "chevronLeft": { w: 384, h: 512 },
    "chevronsLeft": { w: 512, h: 512 },
    "chevronRight": { w: 384, h: 512 },
    "chevronsRight": { w: 512, h: 512 },
    "chevronUp": { w: 512, h: 512 },
    "cloud": { w: 640, h: 512 },
    "copy": { w: 512, h: 512 },
    "ellipsis-vertical": { w: 128, h: 512 },
    "file": { w: 384, h: 512 },
    "imageFile": { w: 384, h: 512 },
    "triangle-exclamation": { w: 512, h: 512 },
    "user": { w: 448, h: 512 },
    "videoFile": { w: 384, h: 512 },
    "xmark": { w: 384, h: 512 },
    "xmarkLarge": { w: 448, h: 512 },
    "h1": { w: 576, h: 512 },
    "h2": { w: 640, h: 512 },
    "h3": { w: 640, h: 512 },
    "h4": { w: 640, h: 512 },
    "h5": { w: 640, h: 512 },
    "h6": { w: 640, h: 512 },
    "bold": { w: 384, h: 512 },
    "italic": { w: 384, h: 512 },
    "underline": { w: 448, h: 512 },
    "ol": { w: 512, h: 512 },
    "ul": { w: 576, h: 512 },
    "code-simple": { w: 576, h: 512 },
    "link": { w: 640, h: 512 },
    "image": { w: 512, h: 512 },
    "youtube": { w: 576, h: 512 },
    "block-quote": { w: 576, h: 512 },
    "arrow-turn-down-left": { w: 512, h: 512 },
    "arrow-turn-down-right": { w: 512, h: 512 },
    "rectangle-wide": { w: 640, h: 512 },
    "edit": { w: 512, h: 512 },
    "delete": { w: 448, h: 512 },
    "open-link-in-new-tab": { w: 512, h: 512 },
    "highlighter-line": { w: 576, h: 512 },
} as const;

export type IconsType = keyof typeof ICONS;
