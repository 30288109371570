import { mobileBoxMargins as mobileBoxMarginsMixin } from "@styles/mixins";
import { ITheme, Palette } from "@styles/theme";
import { srOnly } from "@uxf_base/styles/mixins";
import { isServer } from "@uxf_base/utils/isServer";
import { matchMin } from "@uxf_base/utils/styling";
import { FlattenSimpleInterpolation, css } from "styled-components";

type SelectedDomProperties = "height" | "font-size" | "line-height" | "width";

export const getAbsoluteDomElementSize = (element: HTMLElement, property: SelectedDomProperties): number | null =>
    !isServer ? parseInt(window.getComputedStyle(element).getPropertyValue(property), 10) : null;

export const getResponsiveNumericValueFromArray = (value: number | number[], breakpoint: number): number => {
    if (Array.isArray(value)) {
        if (matchMin(breakpoint) && value[1]) {
            return value[1];
        }

        return value[0];
    }

    return value;
};

export const getColor = (theme: ITheme, color: string | "inherit" | Palette): string | null => {
    if (!color) {
        return null;
    } else if (color === "inherit") {
        return "inherit";
    } else if (theme.color.palette[color as Palette]) {
        return theme.color.palette[color as Palette];
    }

    return color;
};

export const getThemeColor = (theme: ITheme, color: Palette): string | null => {
    return color ? theme.color.palette[color] : null;
};

export const getVisuallyHidden = (visuallyHidden: boolean): FlattenSimpleInterpolation | null =>
    visuallyHidden
        ? css`
              ${srOnly}
          `
        : null;

export const getMobileBoxMargins = (theme: ITheme, mobileBoxMargins: boolean): FlattenSimpleInterpolation | null =>
    mobileBoxMargins
        ? css`
              ${mobileBoxMarginsMixin(theme)}
          `
        : null;
