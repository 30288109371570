import { FOCUS_VISIBLE_CLASSNAME, IS_HOVERABLE_CLASSNAME } from "@styles/constants";
import { Clickable } from "@ui/Clickable";
import { CssHelper } from "@utils/CssHelper";
import { withUnit } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { IRootProps } from "./types";

export const Root = styled(Clickable)<IRootProps>(
    ({ light, variant, theme }) => css`
        && {
            border-radius: ${withUnit(theme.borderRadius.full, "px")};
            display: block;
            flex: 0 0 auto;
            height: ${rem(theme.size.ghostButton.icon)};
            position: relative;
            width: ${rem(theme.size.ghostButton.icon)};

            & > .ghost {
                align-items: center;
                border-radius: ${withUnit(theme.borderRadius.full, "px")};
                color: ${variant ? theme.color.ghostButton.text(variant, light) : null};
                display: flex;
                flex: 0 0 auto;
                height: ${rem(theme.size.ghostButton.radius)};
                justify-content: center;
                margin: -${rem((theme.size.ghostButton.radius - theme.size.ghostButton.icon) / 2)};
                text-align: center;
                transform: translateZ(0);
                transition: ${CssHelper.transition("backgroundColor")};
                width: ${rem(theme.size.ghostButton.radius)};
            }

            &.${IS_HOVERABLE_CLASSNAME} {
                &:hover,
                &:active {
                    & > .ghost {
                        background-color: ${theme.color.ghostButton.background(light)[1]};
                        color: ${variant ? theme.color.ghostButton.text(variant, light) : null};
                    }
                }
            }

            &.${FOCUS_VISIBLE_CLASSNAME} {
                box-shadow: ${theme.shadow.buttonFocus};
                z-index: 1;
            }
        }
    `,
);
