import { useTranslation } from "@translations/use-translation";
import clsx from "clsx";
import React, { forwardRef, useCallback } from "react";
import { Backdrop, CloseButton, Content, Helper, Root } from "./styles";
import { ModalProps } from "./types";

export const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
    const { t } = useTranslation();
    const {
        children,
        className,
        closeButton,
        disableBackdropClose = false,
        handleClose,
        width,
        zIndex,
        ...restProps
    } = props;

    const handleCloseClick = useCallback(() => {
        if (handleClose) {
            handleClose();
        }
    }, [handleClose]);

    return (
        <Root className={clsx("opened-modal", className)} ref={ref} zIndex={zIndex} {...restProps}>
            <Backdrop onClick={!disableBackdropClose ? handleCloseClick : undefined}>
                <Content width={width} onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                    <Helper>{children}</Helper>
                    {!!closeButton && (
                        <CloseButton name="cross" onClick={handleCloseClick} title={t("common:actions.close")} />
                    )}
                </Content>
            </Backdrop>
        </Root>
    );
});

Modal.displayName = "Modal";
