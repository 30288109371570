import { IconSizeEnum } from "@config/icons";
import { Icon } from "@ui/Icon";
import { Span } from "@ui/Text";
import { FC } from "react";
import { Content, Root } from "./styles";
import { IBadgeProps } from "./types";

export const Badge: FC<IBadgeProps> = (props) => {
    const { children, icon, iconSize, iconProps, ...restProps } = props;
    return (
        <Root {...restProps}>
            <Content>
                {icon ? (
                    <>
                        <Icon name={icon} size={iconSize ?? IconSizeEnum.medium} {...iconProps} />
                        <Span visuallyHidden>{children}</Span>
                    </>
                ) : (
                    children
                )}
            </Content>
        </Root>
    );
};

Badge.defaultProps = {
    position: "static",
    variant: "danger",
};
