import { GhostButton, IGhostButtonProps } from "@ui/GhostButton";
import { getColor } from "@utils/styling";
import transparentize from "polished/lib/color/transparentize";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { IContentProps, IRootProps } from "./types";

export const Root = styled("div")<IRootProps>(
    ({ zIndex, theme }) => css`
        background-color: ${theme.color.backdrop.default};
        bottom: 0;
        left: 0;
        overflow-y: auto;
        position: fixed;
        right: 0;
        top: 0;
        z-index: ${theme.zIndex.modal + (zIndex ? zIndex : 0)};
    `,
);

export const Backdrop = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        padding: ${rem(theme.legacyGutter(1))};
        width: 100%;
    `,
);

export const Content = styled("div")<IContentProps>(
    ({ width = "medium", theme }) => css`
        background-color: ${getColor(theme, theme.color.palette.white)};
        border-radius: ${rem(theme.borderRadius.default)};
        box-shadow: ${theme.shadow.modal};
        max-width: ${rem(typeof width === "string" ? theme.size.modal[width] : width)};
        position: relative;
        width: 100%;
    `,
);

export const Helper = styled("div")<HTMLAttributes<HTMLDivElement>>`
    border-radius: inherit;

    & > form {
        border-radius: inherit;
    }
`;

export const CloseButton = styled(GhostButton)<IGhostButtonProps>(
    ({ theme }) => css`
        &&& {
            background-color: ${transparentize(0.5, theme.color.palette.white)};
            position: absolute;
            right: ${rem(theme.legacySpacing(1.5))};
            top: ${rem(theme.legacySpacing(1.5))};
            z-index: 3;
        }
    `,
);
