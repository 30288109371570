import { IconSize, IconSizeEnum, IconsType } from "@config/icons";
import { Palette } from "@styles/theme";
import { Icon } from "@ui/Icon";
import { Loader } from "@ui/Loader/Loader";
import transparentize from "polished/lib/color/transparentize";
import { ForwardRefRenderFunction, forwardRef } from "react";
import { useTheme } from "styled-components";
import { Root } from "./styles";
import { IButtonProps } from "./types";

const ButtonRenderFn: ForwardRefRenderFunction<any, IButtonProps> = (props, ref) => {
    const theme = useTheme();
    const {
        backgroundColor,
        children,
        endComponent,
        endIcon,
        endIconSize,
        gtmId,
        kind,
        size = "medium",
        startComponent,
        startIcon,
        startIconColor,
        startIconSize,
        variant = "primary",
        ...restProps
    } = props;

    const icon = (name: IconsType, className: string, iconSize: IconSize, color?: Palette) => (
        <Icon
            className={className}
            name={name}
            size={iconSize ? iconSize : size === "small" ? IconSizeEnum.medium : IconSizeEnum.large}
            color={color ? theme.color.palette[color] : undefined}
        />
    );

    return (
        <Root
            backgroundColor={backgroundColor}
            data-kind={kind}
            kind={kind}
            loaderComponent={
                <Loader
                    absolute
                    borderRadius="inherit"
                    color={variant}
                    style={{ backgroundColor: transparentize(0.4, theme.color.palette[backgroundColor ?? "white"]) }}
                />
            }
            ref={ref}
            size={size}
            variant={variant}
            {...restProps}
        >
            {!!startIcon && icon(startIcon, "start-icon", startIconSize ?? IconSizeEnum.medium, startIconColor)}
            {!!startComponent && <span className="start-component">{startComponent}</span>}
            <span className="text" id={gtmId}>
                {children}
            </span>
            {!!endComponent && <span className="end-component">{endComponent}</span>}
            {!!endIcon &&
                icon(
                    endIcon,
                    "end-icon",
                    endIcon === "triangleDown" ? IconSizeEnum.large : endIconSize ?? IconSizeEnum.medium,
                )}
        </Root>
    );
};

export const Button = forwardRef<any, IButtonProps>(ButtonRenderFn);

Button.displayName = "Button";
