import { useTranslation } from "@translations/use-translation";
import { ILoaderProps } from "@ui/Loader/types";
import { FC } from "react";
import { useTheme } from "styled-components";
import { LoaderDot, Root } from "./styles";

export const Loader: FC<ILoaderProps> = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { color = "secondary", ...restProps } = props;

    const dotColor = color === "currentColor" ? "currentColor" : theme.color.palette[color];

    return (
        <Root aria-busy="true" role="alert" {...restProps}>
            <svg height={18} role="img" width={30} viewBox="0 0 30 18">
                <title>{t("ui-loader:loading")}</title>
                <g>
                    <LoaderDot height={18} cx="3" cy="9" r="3" fill={dotColor} />
                    <LoaderDot height={18} cx="15" cy="9" r="3" fill={dotColor} />
                    <LoaderDot height={18} cx="27" cy="9" r="3" fill={dotColor} />
                </g>
            </svg>
        </Root>
    );
};
