import { useMyProfileQuery } from "@gql-schema";
import { useRequestEmailVerificationMutation } from "@hooks/mutations/useRequestEmailVerificationMutation";
import { useRefreshQuery } from "@hooks/queries/useRefreshQuery";
import { useMeNullable } from "@profile-zone/hooks/use-me-nullable";
import { ErrorService } from "@services/ErrorService";
import { FlashMessagesService } from "@services/FlashMessagesService";
import { useTranslation } from "@translations/use-translation";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import LogRocket from "logrocket";
import { useCallback, useEffect } from "react";

let flashMessageViewed = false;

function Content() {
    useRefreshQuery();
    const { t } = useTranslation();
    const { data } = useMyProfileQuery();
    const [requestEmailVerificationMutation] = useRequestEmailVerificationMutation();

    useEffect(() => {
        if (!data?.me) {
            return;
        }

        LogRocket.identify(data.me.id.toString(), {
            name: data.me.fullName,
            email: data.me.email || "",
        });
    }, [data?.me]);

    const requestEmailVerification = useCallback(async () => {
        try {
            await requestEmailVerificationMutation({});
            FlashMessagesService.flashMessage({
                title: t("component-core-user-services:link-sent"),
                variant: "primary",
            });
        } catch (e) {
            ErrorService.handleError(e);
        }
    }, [requestEmailVerificationMutation, t]);

    useEffect(() => {
        if (!flashMessageViewed && data && !data.me.emailVerified) {
            FlashMessagesService.flashMessage({
                body: t("component-core-user-services:verify-account"),
                title: t("component-core-user-services:warning-about-email"),
                button: t("component-core-user-services:send-verify-email"),
                buttonProps: {
                    onClick: requestEmailVerification,
                },
                autoDismiss: false,
                variant: "text",
            });
            flashMessageViewed = true;
        }
    }, [data, requestEmailVerification, t]);

    return null;
}

export function LoggedUserServices() {
    const me = useMeNullable();

    return isNotNil(me) ? <Content /> : null;
}
