import { theme } from "@styles/theme";
import { CssHelper } from "@utils/CssHelper";
import { isServer } from "@uxf_base/utils/isServer";
import facepaint from "facepaint";
import em from "polished/lib/helpers/em";
import { css, FlattenSimpleInterpolation } from "styled-components";

// match media shorthands
const matchBetween = (from: number, to: number): boolean =>
    !isServer ? window.matchMedia(`(min-width: ${em(from)}) and (max-width: ${em(to - 1)})`).matches : false;
const matchMin = (from: number): boolean => (!isServer ? window.matchMedia(`(min-width: ${em(from)})`).matches : false);
const matchMax = (to: number): boolean => (!isServer ? window.matchMedia(`(max-width: ${em(to - 1)})`).matches : false);

// get media queries shorthands
const between = (from: number, to: number): string => `@media (min-width: ${em(from)}) and (max-width: ${em(to - 1)})`;
const min = (from: number): string => `@media (min-width: ${em(from)})`;
const max = (to: number): string => `@media (max-width: ${em(to - 1)})`;

// media queries shorthands
const mediaBetween = (from: number, to: number, style: FlattenSimpleInterpolation): string | null => {
    return !CssHelper.emptyCssArray(style)
        ? `
              ${between(from, to)} {
                  ${style}
              }
          `
        : null;
};

const mediaMin = (from: number, style: FlattenSimpleInterpolation): FlattenSimpleInterpolation | null => {
    return !CssHelper.emptyCssArray(style)
        ? css`
              ${min(from)} {
                  ${style}
              }
          `
        : null;
};

const mediaMax = (to: number, style: FlattenSimpleInterpolation): string | null => {
    return !CssHelper.emptyCssArray(style)
        ? `
              ${max(to)} {
                  ${style}
              }
          `
        : null;
};

/* https://github.com/emotion-js/facepaint */

// facePaint shorthands
export const facePaintedMin = facepaint([
    min(theme.breakpoint.sm),
    min(theme.breakpoint.md),
    min(theme.breakpoint.lg),
    min(theme.breakpoint.xl),
    min(theme.breakpoint.xxl),
]);

// first value of input must be null (temporary workaround)
export const facePaintedOnly = facepaint([
    max(theme.breakpoint.sm),
    between(theme.breakpoint.sm, theme.breakpoint.md),
    between(theme.breakpoint.md, theme.breakpoint.lg),
    between(theme.breakpoint.lg, theme.breakpoint.xl),
    between(theme.breakpoint.xl, theme.breakpoint.xxl),
    min(theme.breakpoint.xxl),
]);

export const Responsive = {
    facePaintedMin,
    facePaintedOnly,
    matchBetween,
    matchMax,
    matchMin,
    between,
    min,
    max,
    mediaBetween,
    mediaMax,
    mediaMin,
};
