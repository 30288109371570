// this file is generated automatically, do not change anything manually in the contents of this file

export const twScreens = {
    xs: "36em",
    sm: "48em",
    md: "62em",
    lg: "75em",
    xl: "90em",
};

export type TwScreens = typeof twScreens;
