import { LazyQueryHookOptions } from "@apollo/client/react";

import {
    useClubRequestsLazyQuery,
    useMyClubsLazyQuery,
    useMyEventsLazyQuery,
    useMyHouseInvitationsLazyQuery,
    useMyHouseReuestsLazyQuery,
    useMyHousesLazyQuery,
    useMyHouseWallLazyQuery,
    useMyInvitationsLazyQuery,
    useMyMessengerThreadsLazyQuery,
    useMyNotificationBadgesQuery,
    useMyNotificationsLazyQuery,
    useMyWallLazyQuery,
} from "@gql-schema";
import { useDidUpdateEffect } from "@hooks/useDidUpdateEffect";

export const useRefreshQuery = () => {
    const { data } = useMyNotificationBadgesQuery({
        pollInterval: 25000,
    });

    const baseOptions: LazyQueryHookOptions<any, any> = {
        fetchPolicy: "network-only",
    };
    const [refetchNotifications] = useMyNotificationsLazyQuery(baseOptions);
    const [refetchMyThreads] = useMyMessengerThreadsLazyQuery(baseOptions);
    const [refetchMyHouses] = useMyHousesLazyQuery(baseOptions);
    const [refetchMyClubs] = useMyClubsLazyQuery(baseOptions);
    const [refetchMyClubInvitations] = useMyInvitationsLazyQuery(baseOptions);
    const [refetchMyHouseInvitations] = useMyHouseInvitationsLazyQuery(baseOptions);
    const [refetchMyWall] = useMyWallLazyQuery(baseOptions);
    const [refetchMyHouseWall] = useMyHouseWallLazyQuery(baseOptions);
    const [refetchClubRequests] = useClubRequestsLazyQuery(baseOptions);
    const [refetchHouseRequests] = useMyHouseReuestsLazyQuery(baseOptions);
    const [refetchMyEvents] = useMyEventsLazyQuery(baseOptions);

    const messagesCount = data?.myNotificationBadges?.messages || 0;
    const notificationsCount = data?.myNotificationBadges?.notifications || 0;
    const housesCount = data?.myNotificationBadges?.houses || 0;
    const houseRequestsCount = data?.myNotificationBadges?.houseRequests || 0;
    const houseInvitationsCount = data?.myNotificationBadges?.houseInvitations || 0;
    const clubsCount = data?.myNotificationBadges?.clubs || 0;
    const clubRequestsCount = data?.myNotificationBadges?.clubRequests || 0;
    const clubInvitationsCount = data?.myNotificationBadges?.clubInvitations || 0;
    const eventInvitationsCount = data?.myNotificationBadges?.eventInvitations || 0;
    useDidUpdateEffect(() => {
        if (data) {
            refetchMyThreads();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messagesCount]);

    useDidUpdateEffect(() => {
        if (data) {
            refetchNotifications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationsCount]);

    useDidUpdateEffect(() => {
        if (data) {
            refetchMyHouseWall();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [housesCount]);

    useDidUpdateEffect(() => {
        if (data) {
            refetchMyHouseWall();
            refetchMyHouses();
            refetchHouseRequests();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [houseRequestsCount]);

    useDidUpdateEffect(() => {
        if (data) {
            refetchMyHouseWall();
            refetchMyHouses();
            refetchMyHouseInvitations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [houseInvitationsCount]);

    useDidUpdateEffect(() => {
        if (data) {
            refetchMyWall();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubsCount]);

    useDidUpdateEffect(() => {
        if (data) {
            refetchMyWall();
            refetchMyClubs();
            refetchClubRequests();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubRequestsCount]);

    useDidUpdateEffect(() => {
        if (data) {
            refetchMyClubInvitations();
            refetchMyWall();
            refetchMyClubs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubInvitationsCount]);

    useDidUpdateEffect(() => {
        if (data) {
            refetchMyWall();
            refetchMyHouseWall();
            refetchMyEvents();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventInvitationsCount]);
};
