import { margins } from "@styles/mixins";
import { withUnit } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { IRootProps } from "./types";

export const Root = styled("div")<IRootProps>(
    ({ mb, ml, mr, mt, position, variant, theme }) => css`
        background-color: ${variant ? theme.color.palette[variant] : null};
        border-radius: ${withUnit(theme.borderRadius.full, "px")};
        bottom: ${position === "bottom" ? rem(-theme.size.badge / 4) : null};
        box-shadow: ${variant ? theme.shadow.badge(variant) : null};
        color: ${theme.color.palette.white};
        display: block;
        flex: 0 0 auto;
        font-size: ${rem(theme.typography.variant.caption.fontSize)};
        font-weight: ${theme.typography.fontWeight.semiBold};
        height: ${rem(theme.size.badge)};
        letter-spacing: ${theme.typography.letterSpacing};
        line-height: 1;
        position: ${position !== "static" ? "absolute" : null};
        right: ${rem(-theme.size.badge / 4)};
        text-align: center;
        top: ${position === "top" ? rem(-theme.size.badge / 4) : null};
        width: ${rem(theme.size.badge)};
        ${margins({ mb, ml, mr, mt }, theme)};
    `,
);

export const Content = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        align-items: center;
        border-radius: ${withUnit(theme.borderRadius.full, "px")};
        display: flex;
        height: ${rem(theme.size.badge)};
        overflow: hidden;
        justify-content: center;
        width: ${rem(theme.size.badge)};
    `,
);
