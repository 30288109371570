/* eslint-disable */
/* tslint:disable */
// prettier-ignore
export const translations = {
    "SERVER ERROR": {
        "cs": "Došlo k chybě, v případě přetrvávajících problémů kontaktujte technickou podporu.",
        "en": ""
    },
    "api.auth.login.invalidCredentials": {
        "cs": "Špatné jméno nebo heslo",
        "en": ""
    },
    "api.auth.login.memberNotRegistered": {
        "cs": "Uživatel s tímto e-mailem není v našem systému registrován. Pokud jste zadali e-mail správně a chodí Vám na něj informace z domu či komunity, je potřeba se nejdříve registrovat přes odkaz, který jste dostali v informačním e-mailu.",
        "en": ""
    },
    "api.auth.password.emailNotFound": {
        "cs": "e-mailová adresa neexistuje",
        "en": "Email does not exitst"
    },
    "api.auth.password.invalidToken": {
        "cs": "Neplatný token.",
        "en": "Invalid token"
    },
    "api.auth.reg.appleDataError": {
        "cs": "",
        "en": ""
    },
    "api.auth.reg.duplicateEmail": {
        "cs": "E-mail již existuje.",
        "en": "E-mail already exists"
    },
    "api.auth.reg.fbDataError": {
        "cs": "Chybná data z Facebooku",
        "en": "Invalid data from Facebook"
    },
    "api.auth.reg.googleDataError": {
        "cs": "Chybná data z Googlu",
        "en": "Invalid Data from Google"
    },
    "api.auth.reg.invalidProvider": {
        "cs": "Chybný provider",
        "en": ""
    },
    "api.auth.reg.unknownError": {
        "cs": "Neznámá chyba.",
        "en": "Unknown error"
    },
    "api.auth.verifyEmailInvalidToken": {
        "cs": "Neplatný verifikační token",
        "en": ""
    },
    "api.business.duplicateOrder": {
        "cs": "",
        "en": ""
    },
    "api.business.invoiceCannotDelete": {
        "cs": "",
        "en": ""
    },
    "api.business.invoiceState": {
        "cs": "",
        "en": ""
    },
    "api.club.cuzkCacheUpdate": {
        "cs": "",
        "en": ""
    },
    "api.club.distinctClub": {
        "cs": "",
        "en": ""
    },
    "api.club.duplicateName": {
        "cs": "Název již existuje.",
        "en": "Name already exists"
    },
    "api.club.memberImport.invalidFormat": {
        "cs": "Chybný formát Excel souboru. Použijte vzorový Excel soubor.",
        "en": "Invalid fromat of Excel file. Use example Excel file."
    },
    "api.club.membership.emailDuplicity": {
        "cs": "Na tento mail již byla pozvánka odeslána",
        "en": ""
    },
    "api.club.membership.flatNotFound": {
        "cs": "",
        "en": ""
    },
    "api.club.membershipExists": {
        "cs": "Člen již existuje",
        "en": "Member already exists"
    },
    "api.club.membershipInvalidState": {
        "cs": "Chybný stav členství",
        "en": ""
    },
    "api.club.membershipInvalidToken": {
        "cs": "Platnost vaší pozvánky vypršela",
        "en": ""
    },
    "api.club.membershipLastAdmin": {
        "cs": "Nemůžete opustit skupinu, jste poslední admin",
        "en": ""
    },
    "api.club.membershipMerge.cadastre": {
        "cs": "Nelze párovat dva uživatele s vlastnickým podílem",
        "en": ""
    },
    "api.club.membershipMerge.profile": {
        "cs": "Nelze párovat dva aktivní uživatele",
        "en": ""
    },
    "api.club.membershipMerge.request": {
        "cs": "Nelze párovat s pozvánkou",
        "en": ""
    },
    "api.club.membershipNoEmail": {
        "cs": "",
        "en": ""
    },
    "api.club.membershipRole": {
        "cs": "",
        "en": ""
    },
    "api.core.addressNotFound": {
        "cs": "Adresa nenalezena",
        "en": ""
    },
    "api.core.emailNotVerified": {
        "cs": "Neověřený email",
        "en": ""
    },
    "api.core.forbidden": {
        "cs": "Přístup zamítnut",
        "en": ""
    },
    "api.core.helpDesk": {
        "cs": "Vyskytla se chyba - obraťte se na zákaznickou podporu.",
        "en": ""
    },
    "api.core.invalidInput": {
        "cs": "Špatné vstupní hodnoty.",
        "en": ""
    },
    "api.core.notFound": {
        "cs": "Nenalezeno",
        "en": "Not found"
    },
    "api.core.unauthorized": {
        "cs": "Nejste přihlášeni",
        "en": ""
    },
    "api.core.valueOverflow": {
        "cs": "Překročili jste počet znaků",
        "en": ""
    },
    "api.document.cannotMoveFolderIntoItsChild": {
        "cs": "Nelze přesunout složku do její podsložky",
        "en": ""
    },
    "api.document.cannotMoveFolderIntoItself": {
        "cs": "Vybraná a cílová složka nemůžou být stejné",
        "en": ""
    },
    "api.document.cannotMoveSecuredFolder": {
        "cs": "Složku 'Zabezpečená složka' nelze přesunout",
        "en": ""
    },
    "api.document.cannotMoveWallFolder": {
        "cs": "Složku 'Dokumenty ze zdi' nelze přesunout",
        "en": ""
    },
    "api.document.cannotRemoveNonEmptyFolder": {
        "cs": "Nelze smazat složku, která není prázdná",
        "en": ""
    },
    "api.document.cannotRemoveSecuredFolder": {
        "cs": "Složku 'Zabezpečená složka' nelze smazat",
        "en": ""
    },
    "api.document.cannotRemoveWallFolder": {
        "cs": "Složku 'Dokumenty ze zdi' nelze smazat",
        "en": ""
    },
    "api.document.cannotRenameSecuredFolder": {
        "cs": "Složku 'Zabezpečená složka' nelze přejmenovat",
        "en": ""
    },
    "api.document.cannotRenameWallFolder": {
        "cs": "Složku 'Dokumenty ze zdi' nelze přejmenovat",
        "en": ""
    },
    "api.event.attendOver": {
        "cs": "",
        "en": ""
    },
    "api.messenger.createThreadError": {
        "cs": "Nepovedlo se vytvořit komunikační vlákno",
        "en": ""
    },
    "api.photo.cannotMove": {
        "cs": "Fotku nelze přesunout.",
        "en": ""
    },
    "api.photo.cannotRemoveNonEmptyGallery": {
        "cs": "Fotogalerie obsahuje fotky, které nemáte právo smazat",
        "en": ""
    },
    "api.photo.cannotRemoveWallGallery": {
        "cs": "Fotogalerii 'Fotky ze zdi' nelze smazat",
        "en": ""
    },
    "api.photo.cannotRenameWallGallery": {
        "cs": "Fotogalerii 'Fotky ze zdi' nelze přejmenovat",
        "en": ""
    },
    "api.serviceCompany.tooManyEmployees": {
        "cs": "Překročen maximální počet zaměstnanců",
        "en": ""
    },
    "api.storage.overLimit": {
        "cs": "Překročen limit úložiště.",
        "en": ""
    },
    "api.survey.allReadyVoted": {
        "cs": "V této anketě jste již hlasoval",
        "en": ""
    },
    "api.survey.overEnd": {
        "cs": "V této anketě již není možné hlasovat",
        "en": ""
    },
    "api.vote.alreadyPublished": {
        "cs": "Hlasování již bylo publikováno",
        "en": ""
    },
    "api.vote.doesntExist": {
        "cs": "",
        "en": ""
    },
    "api.vote.flatWithoutNumerator": {
        "cs": "Hlasování nelze vytvořit, aktualizujte data z katastru nemovitostí.",
        "en": ""
    },
    "api.vote.invalidSchedule": {
        "cs": "Hlasování má špatně nastavenou dobu trvání",
        "en": ""
    },
    "api.vote.notPublished": {
        "cs": "Hlasování ještě nebylo publikováno",
        "en": ""
    },
    "api.vote.unitDuplicateMembership": {
        "cs": "Jednotka nemůže mít stejného majitele vícekrát",
        "en": ""
    },
    "club.format.cadastre": {
        "cs": {
            "zero": "Žádné změny",
            "one": "Byla zaznamenána {{count}} změna",
            "few": "Byly zaznamenány {{count}} změny",
            "other": "Bylo zaznamenáno {{count}} změn"
        },
        "en": ""
    },
    "club.format.files": {
        "cs": {
            "zero": "souborů",
            "one": "soubor",
            "few": "soubory",
            "other": "souborů"
        },
        "en": ""
    },
    "club.format.flats": {
        "cs": {
            "zero": "bytových jednotek",
            "one": "bytová jednotka",
            "few": "bytové jednotky",
            "other": "bytových jednotek"
        },
        "en": ""
    },
    "club.format.folders": {
        "cs": {
            "zero": "složek",
            "one": "složka",
            "few": "složky",
            "other": "složek"
        },
        "en": ""
    },
    "club.format.insolvency": {
        "cs": {
            "zero": "Žádné změny",
            "one": "Byla zaznamenána {{count}} změna",
            "few": "Byly zaznamenány {{count}} změny",
            "other": "Bylo zaznamenáno {{count}} změn"
        },
        "en": ""
    },
    "club.format.members": {
        "cs": {
            "zero": "členů",
            "one": "člen",
            "few": "členové",
            "other": "členů"
        },
        "en": ""
    },
    "club.format.owners": {
        "cs": {
            "zero": "majitelů",
            "one": "majitel",
            "few": "majitelé",
            "other": "majitelů"
        },
        "en": ""
    },
    "club.format.posts": {
        "cs": {
            "zero": "příspěvků",
            "one": "příspěvek",
            "few": "příspěvky",
            "other": "příspěvků"
        },
        "en": ""
    },
    "club.format.requests": {
        "cs": {
            "zero": "žádostí",
            "one": "žádost",
            "few": "žádosti",
            "other": "žádostí"
        },
        "en": ""
    },
    "contact.format.contacts": {
        "cs": {
            "zero": "žádné kontakty",
            "one": "{{count}} kontakt",
            "few": "{{count}} kontakty",
            "other": "{{count}} kontaktů"
        },
        "en": ""
    },
    "datetime.format.beforeHours": {
        "cs": {
            "zero": "před méně než hodinou",
            "one": "před 1 hodinou",
            "few": "před {{count}} hodinami",
            "other": "před {{count}} hodinami"
        },
        "en": ""
    },
    "datetime.format.beforeMinutes": {
        "cs": {
            "zero": "před méně než minutou",
            "one": "před 1 minutou",
            "few": "před {{count}} minutami",
            "other": "před {{count}} minutami"
        },
        "en": ""
    },
    "datetime.format.rightNow": {
        "cs": "právě teď",
        "en": ""
    },
    "datetime.format.yesterdayAt": {
        "cs": "včera v {{time}}",
        "en": ""
    },
    "document.format.documentsCount": {
        "cs": {
            "zero": "",
            "one": "dokument",
            "few": "{{count}} dokumenty",
            "other": "{{count}} dokumentů"
        },
        "en": ""
    },
    "document.format.foldersCount": {
        "cs": {
            "zero": "",
            "one": "složka",
            "few": "{{count}} složky",
            "other": "{{count}} složek"
        },
        "en": ""
    },
    "document.format.moveFoldersCount": {
        "cs": {
            "zero": "",
            "one": "složku",
            "few": "{{count}} složky",
            "other": "{{count}} složek"
        },
        "en": ""
    },
    "error.unknown": {
        "cs": "Něco se pokazilo. Pracujeme na opravě.",
        "en": "Unknown error."
    },
    "event.format.attendees": {
        "cs": {
            "zero": "nikdo se nezúčastní",
            "one": "{{count}} člověk",
            "few": "{{count}} lidé",
            "other": "{{count}} lidí"
        },
        "en": ""
    },
    "legalAid.format.resultCount": {
        "cs": {
            "zero": "Nenalezen žádný výsledek obsahující",
            "one": "Nalezen jeden výsledek obsahující",
            "few": "Nalezeny {{count}} výsledky obsahující",
            "other": "Nalezeno {{count}} výsledků obsahujících"
        },
        "en": ""
    },
    "magazine.format.articles": {
        "cs": {
            "zero": "Nenalezen žádný článek obsahující",
            "one": "Nalezen jeden článek obsahující",
            "few": "Nalezeny {{count}} články obsahující",
            "other": "Nalezeno {{count}} článků obsahujících"
        },
        "en": ""
    },
    "pricelist.format.daysRemaining": {
        "cs": {
            "zero": "Pozor! Dnes je poslední den verze zdarma.",
            "one": "Pozor! Zbývá poslední den verze zdarma.",
            "few": "Pozor! Už jen {{count}} dny do konce verze zdarma.",
            "other": "Pozor! Už jen {{count}} dní do konce verze zdarma."
        },
        "en": ""
    },
    "serviceCompany.format.housesCount": {
        "cs": {
            "zero": "žádného domu",
            "one": "1 domu",
            "few": "{{count}} domů",
            "other": "{{count}} domů"
        },
        "en": ""
    },
    "serviceCompany.sendDocumentsModal.title": {
        "cs": {
            "zero": "Odeslání 0 dokumentů",
            "one": "Odeslání 1 dokumentu",
            "few": "Odeslání {{count}} dokumentů",
            "other": "Odeslání {{count}} dokumentů"
        },
        "en": ""
    },
    "shared.format.daysRemaining": {
        "cs": {
            "zero": "dní",
            "one": "den",
            "few": "dny",
            "other": "dní"
        },
        "en": ""
    },
    "survey.format.anonymVoters": {
        "cs": {
            "zero": "Anonymní anketa skončila (nikdo nehlasoval)",
            "one": "Anonymní anketa skončila (1 hlasující)",
            "few": "Anonymní anketa skončila ({{count}} hlasující)",
            "other": "Anonymní anketa skončila ({{count}} hlasujících)"
        },
        "en": ""
    },
    "survey.format.answers": {
        "cs": {
            "zero": "0 hlasů",
            "one": "1 hlas",
            "few": "{{count}} hlasy",
            "other": "{{count}} hlasů"
        },
        "en": ""
    },
    "survey.format.voters": {
        "cs": {
            "zero": "Anketa skončila (nikdo nehlasoval)",
            "one": "Anketa skončila (1 hlasující)",
            "few": "Anketa skončila ({{count}} hlasující)",
            "other": "Anketa skončila ({{count}} hlasujících)"
        },
        "en": ""
    },
    "table.selectedRowsCount": {
        "cs": {
            "zero": "Žádná položka není vybrána",
            "one": "Vybrána 1 položka",
            "few": "Vybrány {{count}} položky",
            "other": "Vybráno {{count}} položek"
        },
        "en": ""
    },
    "vote.format.documentsToApprove": {
        "cs": {
            "zero": "Žádné hlasování bez schválených dokumentů.",
            "one": "Jedno hlasování čeká na vaše schválení dokumentů.",
            "few": "{{count}} hlasování čekají na vaše schválení dokumentů",
            "other": "{{count}} hlasování čeká na vaše schválení dokumentů"
        },
        "en": ""
    },
    "vote.format.owners": {
        "cs": {
            "zero": "0 vlastníků",
            "one": "jednoho vlastníka",
            "few": "{{count}} vlastníky",
            "other": "{{count}} vlastníků"
        },
        "en": ""
    },
    "vote.format.persons": {
        "cs": {
            "zero": "žádná osoba",
            "one": "1 osoba",
            "few": "{{count}} osoby",
            "other": "{{count}} osob"
        },
        "en": ""
    },
    "vote.format.voteForUnits": {
        "cs": {
            "zero": "0 jednotek",
            "one": "1 jednotku",
            "few": "{{count}} jednotky",
            "other": "{{count}} jednotek"
        },
        "en": ""
    },
    "wallpost.format.comments": {
        "cs": {
            "zero": "žádný komentář",
            "one": "1 komentář",
            "few": "{{count}} komentáře",
            "other": "{{count}} komentářů"
        },
        "en": ""
    },
    "wallpost.format.likes": {
        "cs": {
            "zero": "nikomu se nelíbí",
            "one": "1 člověku se líbí",
            "few": "{{count}} lidem se líbí",
            "other": "{{count}} lidem se líbí"
        },
        "en": ""
    },
    "wallpost.format.subcomments": {
        "cs": {
            "zero": "žádná odpověď na komentář",
            "one": "1 odpověď na komentář",
            "few": "{{count}} odpovědi na komentář",
            "other": "{{count}} odpovědí na komentář"
        },
        "en": ""
    }
}
