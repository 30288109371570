import { StringNumberBooleanType } from "@app-types/css";

const is = (n: any): boolean => n !== undefined && n !== null;
const num = (n: any): boolean => typeof n === "number" && !isNaN(n);
const str = (s: any): boolean => typeof s === "string" && s !== "";
const px = (n: any): string => (num(n) ? n + "px" : n);
const negativeNumber = (n: any): string | number => (num(n) ? -n : "-" + n);

const hasValue = (value: StringNumberBooleanType | StringNumberBooleanType[]): boolean =>
    value ? Array.isArray(value) && value.length > 0 : false;

const trimTrailingZeros = (value: string) => String(value).replace(/\.?0+$/, "");

const percent = (inputValue: number, maxValue = 100, precision = 2): number =>
    parseFloat(trimTrailingZeros(((inputValue / maxValue) * 100).toFixed(precision)));

export const ValueHelper = {
    hasValue,
    is,
    negativeNumber,
    num,
    percent,
    px,
    str,
    trimTrailingZeros,
};
