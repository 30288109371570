import { ActionTypes, IState } from "./types";

const initialState: IState = {
    isDrawerOpen: true,
};

const reducer = (state: IState = initialState, action: ActionTypes): IState => {
    switch (action.type) {
        case "layout/TOGGLE_DRAWER":
            return {
                ...state,
                isDrawerOpen: !state.isDrawerOpen,
            };
        case "layout/OPEN_DRAWER":
            return {
                ...state,
                isDrawerOpen: true,
            };
        case "layout/CLOSE_DRAWER":
            return {
                ...state,
                isDrawerOpen: false,
            };

        default:
            return state;
    }
};

export default reducer;
