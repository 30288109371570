import { ITheme } from "@styles/theme";
import { getCssDesktopProp, getCssProp, mediaMax, mediaMin } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import { css } from "styled-components";

type Margins = "auto" | string | number | Array<"auto" | string | number>;

export interface IMargins {
    mb?: Margins;
    ml?: Margins;
    mr?: Margins;
    mt?: Margins;
}

export const margins = ({ mb, ml, mr, mt }: IMargins, theme: ITheme) => css`
    margin-bottom: ${mb ? getCssProp(mb) : null};
    margin-left: ${ml ? getCssProp(ml) : null};
    margin-right: ${mr ? getCssProp(mr) : null};
    margin-top: ${mt ? getCssProp(mt) : null};

    ${mediaMin(theme.breakpoint.md)} {
        margin-bottom: ${mb ? getCssDesktopProp(mb) : null};
        margin-left: ${ml ? getCssDesktopProp(ml) : null};
        margin-right: ${mr ? getCssDesktopProp(mr) : null};
        margin-top: ${mt ? getCssDesktopProp(mt) : null};
    }
`;

export const mobileBoxMargins = (theme: ITheme, margin = 16) => css`
    ${mediaMax(theme.breakpoint.sm)} {
        border-left-style: none;
        border-radius: 0;
        border-right-style: none;
        margin-left: ${rem(-margin)};
        margin-right: ${rem(-margin)};
        width: calc(100% + ${rem(margin * 2)});
    }
`;

export const truncate = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
