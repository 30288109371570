import em from "polished/lib/helpers/em";
import rem from "polished/lib/helpers/rem";
import { isServer } from "./isServer";

export type CssTimeUnits = "ms" | "s";
export type CssAbsoluteLengthsUnits = "cm" | "mm" | "in" | "px" | "pt" | "pc" | "deg" | "rad";
export type CssRelativeLengthsUnits = "em" | "ex" | "ch" | "rem" | "vw" | "vh" | "vmin" | "vmax" | "%";
export type CssUnits = CssTimeUnits | CssAbsoluteLengthsUnits | CssRelativeLengthsUnits;

type StringNumber = string | number | null | undefined;

export const is = (n: any): boolean => n !== undefined && n !== null;
export const num = (n: any): boolean => typeof n === "number" && !isNaN(n);
export const str = (s: any): boolean => typeof s === "string" && s !== "";
export const px = (n: any): string => (num(n) ? n + "px" : n);

// easily return string with value with proper unit
export const withUnit = (value: number, unit: CssUnits): string => `${unit === "%" ? value * 100 : value}${unit}`;

// media queries shorthands
export const mediaHide = "@media not all";
export const mediaMin = (min: number): string => (min ? `@media (min-width: ${em(min)})` : mediaHide);
export const mediaMax = (max: number): string => (max ? `@media (max-width: ${em(max - 1)})` : mediaHide);

// match media shorthands
export const matchMin = (min: number): boolean =>
    !isServer ? window.matchMedia(`(min-width: ${em(min)})`).matches : false;

// get CSS value from input (return string if number or string and null if other)
export const getCssValue = (input: StringNumber): StringNumber =>
    num(input) ? rem(input as any) : str(input) ? input : null;

// get CSS properties
// ---

// get css property from input prop:
// return string if input is string or number and return null if other
// if input is an array use its first item
export const getCssProp = (input: any): StringNumber =>
    Array.isArray(input)
        ? !!input[0] || input[0] === 0
            ? getCssValue(input[0])
            : null
        : !!input || input === 0
          ? getCssValue(input)
          : null;

// get css property from input prop:
// return string if input is string or number and return null if other
// if input is an array use its second item
// return null if input is not an array
export const getCssDesktopProp = (input: any): StringNumber =>
    (Array.isArray(input) && !!input[1]) || input[1] === 0 ? getCssValue(input[1]) : null;
