import { initializeStore } from "@uxf_base/config/configureStore";
import { isBrowser } from "@uxf_base/utils/isBrowser";
import { Component } from "react";
import { Store } from "redux";
import { IReduxState } from "../../types/Redux";

const __NEXT_REDUX_STORE__ = "__NEXT_REDUX_STORE__";

const getOrCreateStore = (initialState: Partial<IReduxState> = {}): Store<IReduxState> => {
    // Always make a new store if server, otherwise state is shared between requests
    if (!isBrowser) {
        return initializeStore(initialState);
    }

    // Create store if unavailable on the client and set it on the window object
    if (!window[__NEXT_REDUX_STORE__ as any]) {
        (window[__NEXT_REDUX_STORE__ as any] as any) = initializeStore(initialState);
    }
    return window[__NEXT_REDUX_STORE__ as any] as any;
};

const withReduxStore = (App: any) => {
    return class AppWithRedux extends Component {
        private readonly reduxStore: Store<IReduxState>;

        public static async getInitialProps(appContext: any) {
            // Get or Create the store with `undefined` as initialState
            // This allows you to set a custom default initialState
            const reduxStore = getOrCreateStore();

            // Provide the store to getInitialProps of pages
            appContext.ctx.reduxStore = reduxStore;

            let appProps = {};
            if (typeof App.getInitialProps === "function") {
                appProps = await App.getInitialProps.call(App, appContext);
            }

            return {
                ...appProps,
                initialReduxState: reduxStore.getState(),
            };
        }

        constructor(props: any) {
            super(props);
            this.reduxStore = getOrCreateStore(props.initialReduxState);
        }

        public render(): JSX.Element {
            return <App {...this.props} reduxStore={this.reduxStore} />;
        }
    };
};

export default withReduxStore;
