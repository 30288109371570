import {
    marginMixin,
    paddingMixin,
    responsiveHiddenMixin,
    ResponsiveHiddenMixinProps,
    responsiveSrOnlyMixin,
    ResponsiveSrOnlyMixinProps,
} from "@styles/newMixins";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { IBoxProps } from "./types";

const injectCss = (style?: FlattenSimpleInterpolation) => {
    if (style && style.some((i) => i)) {
        return css`
            && {
                ${style}
            }
        `;
    }
    return null;
};

export const Box = styled("div")<IBoxProps>(
    ({
        $css,
        alignSelf,
        backgroundColor,
        borderWidth,
        borderStyle,
        borderColor,
        borderRadius,
        boxDisplay,
        boxHeight,
        boxPosition,
        boxShadow,
        boxWidth,
        breakInside,
        columnCount,
        columnFill,
        columnGap,
        gridColumn,
        flexBasis,
        flexGrow,
        flexShrink,
        gridArea,
        justifySelf,
        maxHeight,
        maxWidth,
        minHeight,
        minWidth,
        mb,
        ml,
        mr,
        mt,
        pb,
        overFlow,
        permanentlyHidden,
        pl,
        pr,
        pt,
        textColor,
        visuallyHidden,
        zIndex,
    }) => css`
        display: block;
        min-width: 0;
        width: 100%;

        ${Responsive.facePaintedMin({
            alignSelf: CssHelper.value(alignSelf),
            backgroundColor: CssHelper.themePaletteValue(backgroundColor),
            borderWidth: CssHelper.value(borderWidth),
            borderStyle: CssHelper.value(borderStyle),
            borderColor: CssHelper.themePaletteValue(borderColor),
            borderRadius: CssHelper.themeBorderRadiusValue(borderRadius),
            boxShadow: CssHelper.value(boxShadow),
            breakInside: CssHelper.value(breakInside),
            color: CssHelper.themePaletteValue(textColor),
            columnCount: CssHelper.number(columnCount),
            columnFill: CssHelper.value(columnFill),
            columnGap: CssHelper.value(columnGap),
            display: CssHelper.value(boxDisplay),
            flexBasis: CssHelper.value(flexBasis),
            flexGrow: CssHelper.string(flexGrow),
            flexShrink: CssHelper.string(flexShrink),
            gridArea: CssHelper.value(gridArea),
            height: CssHelper.value(boxHeight),
            justifySelf: CssHelper.value(justifySelf),
            maxHeight: CssHelper.value(maxHeight),
            maxWidth: CssHelper.value(maxWidth),
            minHeight: CssHelper.value(minHeight),
            minWidth: CssHelper.value(minWidth),
            overflow: CssHelper.value(overFlow),
            position: CssHelper.value(boxPosition),
            width: CssHelper.value(boxWidth),
            zIndex: CssHelper.number(zIndex),
            gridColumn: CssHelper.value(gridColumn),
        })}

        ${marginMixin({ mb, ml, mr, mt })};
        ${paddingMixin({ pb, pl, pr, pt })};

        && {
            ${typeof visuallyHidden === "object"
                ? responsiveSrOnlyMixin(visuallyHidden as ResponsiveSrOnlyMixinProps)
                : visuallyHidden
                  ? responsiveSrOnlyMixin({ condition: visuallyHidden })
                  : null}

            ${typeof permanentlyHidden === "object"
                ? responsiveHiddenMixin(permanentlyHidden as ResponsiveHiddenMixinProps)
                : permanentlyHidden
                  ? responsiveHiddenMixin({ condition: permanentlyHidden })
                  : null};
        }

        ${injectCss($css)};
    `,
);
