import {
    BooleanType,
    CssUnits,
    NumberType,
    StringNumberType,
    StringType,
    ThemeBorderRadiusType,
    ThemeFontWeightType,
    ThemePaletteType,
    ThemeTextVariantType,
} from "@app-types/css";
import { ITheme, theme } from "@styles/theme";
import { ArrayHelper } from "@utils/ArrayHelper";
import { StringHelper } from "@utils/StringHelper";
import { ValueHelper } from "@utils/ValueHelper";
import { Property } from "csstype";
import rem from "polished/lib/helpers/rem";
import { CSSProperties } from "react";
import { FlattenSimpleInterpolation } from "styled-components";

const emptyCssArray = (array: FlattenSimpleInterpolation) => array.every((item) => !item);

// format CSS value (return string if number or string and null if other)
const formatValue = (input: StringNumberType): StringNumberType =>
    ValueHelper.num(input)
        ? input === 0
            ? input
            : rem(input as string | number)
        : ValueHelper.str(input)
          ? input
          : null;

const formatAspectRatio = (input: NumberType): StringNumberType =>
    input === 0
        ? input
        : input
          ? `${parseFloat(ValueHelper.trimTrailingZeros(((1 / input) * 100).toFixed(2)))}%`
          : null;

const formatNumberToString = (input: NumberType): StringType => (ValueHelper.num(input) ? String(input) : null);

const value = (input: StringNumberType | StringNumberType[]): StringNumberType | StringNumberType[] => {
    if (ArrayHelper.isArray(input)) {
        return (input as StringNumberType[]).map((item) => formatValue(item));
    }
    return formatValue(input as StringNumberType);
};

const number = (input: NumberType | NumberType[]): StringType | StringType[] => {
    if (ArrayHelper.isArray(input)) {
        return (input as NumberType[]).map((item) => formatNumberToString(item));
    }
    return formatNumberToString(input as NumberType);
};

const string = (input: StringType | StringType[]): StringType | StringType[] => {
    if (ArrayHelper.isArray(input)) {
        return (input as StringType[]).map((item) => formatValue(item) as StringType);
    }
    return formatValue(input as StringType) as StringType;
};

const aspectRatio = (input: NumberType | NumberType[]): StringNumberType | StringNumberType[] => {
    if (ArrayHelper.isArray(input)) {
        return (input as NumberType[]).map((item) => formatAspectRatio(item));
    }
    return formatAspectRatio(input as NumberType);
};

// get specified responsive CSS value from boolean input
const valueFromBoolean = (
    condition: BooleanType | BooleanType[],
    input: string | number,
    falsyValue: string | number,
): StringNumberType | StringNumberType[] => {
    if (ArrayHelper.isArray(condition)) {
        return (condition as BooleanType[]).map((item) =>
            item === true ? formatValue(input) : item === null ? null : falsyValue,
        ) as StringNumberType[];
    }
    return condition ? formatValue(input) : null;
};

const themeBorderRadiusValue = (
    borderRadius: ThemeBorderRadiusType | ThemeBorderRadiusType[],
): StringNumberType | StringNumberType[] => {
    if (ArrayHelper.isArray(borderRadius)) {
        return (borderRadius as ThemeBorderRadiusType[]).map((radius) =>
            radius
                ? formatValue((theme.borderRadius as ITheme["borderRadius"])[radius as keyof ITheme["borderRadius"]])
                : null,
        );
    }
    return (borderRadius as ThemeBorderRadiusType)
        ? formatValue((theme.borderRadius as ITheme["borderRadius"])[borderRadius as keyof ITheme["borderRadius"]])
        : null;
};

const themePaletteValue = (textColor: ThemePaletteType | ThemePaletteType[]): StringType | StringType[] => {
    if (ArrayHelper.isArray(textColor)) {
        return (textColor as ThemePaletteType[]).map((color) =>
            color
                ? (theme.color.palette as ITheme["color"]["palette"])[color as keyof ITheme["color"]["palette"]]
                : null,
        );
    }
    return (textColor as ThemePaletteType)
        ? (theme.color.palette as ITheme["color"]["palette"])[textColor as keyof ITheme["color"]["palette"]]
        : null;
};

const themeTextVariantFontSizeValue = (
    textVariant: ThemeTextVariantType | ThemeTextVariantType[],
): StringType | StringType[] => {
    if (ArrayHelper.isArray(textVariant)) {
        return (textVariant as ThemeTextVariantType[]).map((variant) =>
            variant
                ? (formatValue(
                      (theme.typography.variant as ITheme["typography"]["variant"])[
                          variant as keyof ITheme["typography"]["variant"]
                      ]?.fontSize,
                  ) as StringType)
                : null,
        );
    }
    return (textVariant as ThemeTextVariantType)
        ? (formatValue(
              (theme.typography.variant as ITheme["typography"]["variant"])[
                  textVariant as keyof ITheme["typography"]["variant"]
              ]?.fontSize,
          ) as StringType)
        : null;
};

const themeTextVariantLineHeightValue = (
    textVariant: ThemeTextVariantType | ThemeTextVariantType[],
): StringType | StringType[] => {
    if (ArrayHelper.isArray(textVariant)) {
        return (textVariant as ThemeTextVariantType[]).map((variant) =>
            variant
                ? (formatValue(
                      (theme.typography.variant as ITheme["typography"]["variant"])[
                          variant as keyof ITheme["typography"]["variant"]
                      ]?.lineHeight,
                  ) as StringType)
                : null,
        );
    }
    return (textVariant as ThemeTextVariantType)
        ? (formatValue(
              (theme.typography.variant as ITheme["typography"]["variant"])[
                  textVariant as keyof ITheme["typography"]["variant"]
              ]?.lineHeight,
          ) as StringType)
        : null;
};

const themeFontWeightValue = (fontWeight: ThemeFontWeightType | ThemeFontWeightType[]): NumberType | NumberType[] => {
    if (ArrayHelper.isArray(fontWeight)) {
        return (fontWeight as ThemeFontWeightType[]).map((weight) =>
            weight
                ? (theme.typography.fontWeight as ITheme["typography"]["fontWeight"])[
                      weight as keyof ITheme["typography"]["fontWeight"]
                  ]
                : null,
        );
    }
    return (fontWeight as ThemeFontWeightType)
        ? (theme.typography.fontWeight as ITheme["typography"]["fontWeight"])[
              fontWeight as keyof ITheme["typography"]["fontWeight"]
          ]
        : null;
};

export const numericValueFromArray = (input: number | number[], matchBreakpoint: boolean, index: number): number => {
    if (ArrayHelper.isArray(input)) {
        if (matchBreakpoint && (input as number[])[index]) {
            return (input as number[])[index];
        }
        return (input as number[])[0];
    }
    return input as number;
};

// easily return string with value with proper unit
const withUnit = (input: number, unit: CssUnits): string => `${input}${unit}`;

const transition = (
    property: keyof CSSProperties,
    duration = 400,
    easing: Property.TransitionTimingFunction = "ease-in-out",
): string => `${StringHelper.camelCaseToDash(property)} ${withUnit(duration, "ms")} ${easing}`;

const encodedSvg = (svg: string): string => `url("data:image/svg+xml; utf8, ${escape(svg)}")`;

const columnsToPercent = (columns: number, totalColumns = 12, gutter = 32) => {
    return `calc(${gutter !== 0 ? `(100% + ${rem(gutter)})` : "100%"} / ${totalColumns} * ${columns})`;
};

const spacing = (input?: number | null, factor = 8) => (input ? input * factor : 0);

export const CssHelper = {
    aspectRatio,
    columnsToPercent,
    emptyCssArray,
    encodedSvg,
    formatValue,
    formatAspectRatio,
    number,
    numericValueFromArray,
    spacing,
    string,
    themeBorderRadiusValue,
    themeFontWeightValue,
    themePaletteValue,
    themeTextVariantFontSizeValue,
    themeTextVariantLineHeightValue,
    transition,
    value,
    valueFromBoolean,
    withUnit,
};
