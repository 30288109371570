import { IS_HOVERABLE_CLASSNAME } from "@styles/constants";
import { IMargins, margins } from "@styles/mixins";
import { ITheme } from "@styles/theme";
import { getVisuallyHidden } from "@utils/styling";
import { buttonReset } from "@uxf_base/styles/mixins";
import styled, { css } from "styled-components";
import { IButtonLinkProps, IDummyLinkProps, IExternalLinkProps } from "./types";

export const buttonStyle = (
    theme: ITheme,
    mb: IMargins["mb"],
    ml: IMargins["ml"],
    mr: IMargins["mr"],
    mt: IMargins["mt"],
    visuallyHidden?: boolean,
) => css`
    ${buttonReset};
    box-sizing: border-box;
    color: inherit;
    position: relative;

    &:active,
    &:focus {
        outline: 0;
    }

    &:disabled,
    &[aria-disabled="true"] {
        opacity: 0.3;
        pointer-events: none;
    }

    &[aria-busy="true"] {
        pointer-events: none;
    }

    ${visuallyHidden
        ? css`
              && {
                  ${getVisuallyHidden(visuallyHidden)};
              }
          `
        : null};

    ${margins({ mb, ml, mr, mt }, theme)};
`;

export const ExternalLink = styled("a")<IExternalLinkProps>(
    ({ mb, ml, mr, mt, visuallyHidden, theme }) => css`
        ${buttonStyle(theme, mb, ml, mr, mt, visuallyHidden)};
        cursor: pointer;
    `,
);

export const ButtonLink = styled("button")<IButtonLinkProps>(
    ({ mb, ml, mr, mt, visuallyHidden, theme }) => css`
        ${buttonStyle(theme, mb, ml, mr, mt, visuallyHidden)};
    `,
);

export const DummyLink = styled("span")<IDummyLinkProps>(
    ({ mb, ml, mr, mt, visuallyHidden, theme }) => css`
        ${buttonStyle(theme, mb, ml, mr, mt, visuallyHidden)};

        &.${IS_HOVERABLE_CLASSNAME} {
            & * {
                cursor: default;
            }
        }
    `,
);
