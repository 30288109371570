import { config } from "@config/config";
import { AppInstance } from "@shared/utils/app-instance";
// eslint-disable-next-line no-restricted-imports
import useBaseTranslation from "next-translate/useTranslation";
import { useMemo } from "react";
import { TypeSafeTranslate } from "../../next-translate";

export function useTranslation(): TypeSafeTranslate {
    const { t, lang } = useBaseTranslation();

    return useMemo<TypeSafeTranslate>(
        () => ({
            t: (key, query, options) => {
                const queryWithGeneral = {
                    ...query,
                    general_application_name: config.TRANSLATION_APPLICATION_NAME,
                    general_domain: config.TRANSLATION_DOMAIN,
                    general_business_email: config.TRANSLATION_BUSINESS_EMAIL,
                    general_caretaker_email: config.TRANSLATION_CARETAKER_EMAIL,
                };

                if (AppInstance.isSlovak) {
                    const skKey = `${key}--sk`;
                    const skTranslatedText = t(skKey as any, queryWithGeneral);
                    if (skTranslatedText !== skKey) {
                        return skTranslatedText;
                    }
                }

                return t(key, queryWithGeneral, options);
            },
            lang,
        }),
        [t, lang],
    );
}
