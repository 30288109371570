import ExtendableError from "es6-error";

export class RedirectToUrl extends ExtendableError {
    public readonly url: string;

    constructor(url: string) {
        super("Redirect error");
        this.url = url;
    }
}
