import * as process from "process";

export const config = {
    ALLOWED_LANGUAGES: "cs",
    AUTHORIZATION_HEADER: process.env.NEXT_PUBLIC_AUTHORIZATION_HEADER || "X-Authorization",
    DEFAULT_LANGUAGE: process.env.DEFAULT_LANGUAGE,
    FACEBOOK_APP_ID: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
    FRONTEND_URL: process.env.NEXT_PUBLIC_FRONTEND_URL,
    FRONTEND_WS_URL: process.env.NEXT_PUBLIC_FRONTEND_WS_URL,
    GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
    GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    LOGROCKET_ID: process.env.NEXT_PUBLIC_LOGROCKET_ID,
    SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    STAGE: process.env.NEXT_PUBLIC_STAGE as "local" | "dev" | "sandbox" | "prod",
    TRANSLATION_APPLICATION_NAME: process.env.NEXT_PUBLIC_TRANSLATION_APPLICATION_NAME ?? "",
    TRANSLATION_DOMAIN: process.env.NEXT_PUBLIC_TRANSLATION_DOMAIN ?? "",
    TRANSLATION_BUSINESS_EMAIL: process.env.NEXT_PUBLIC_TRANSLATION_BUSINESS_EMAIL ?? "",
    TRANSLATION_CARETAKER_EMAIL: process.env.NEXT_PUBLIC_TRANSLATION_CARETAKER_EMAIL ?? "",
    WEB_PUSH_PUBLIC_KEY: process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY,
};
