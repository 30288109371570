const camelCaseToDash = (content: string): string => {
    return content.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

/* https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1 */
const slugify = (content: string): string => {
    const a = "àáäâãåăæąçćčđďèéěėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;";
    const b = "aaaaaaaaacccddeeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return content
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
};

const filterByTerm = (input: string, term: string): boolean => slugify(input).includes(slugify(term));

const decimalSeparatorReplace = (input: string): string => input.replace(".", ",").replace(",00", "");

export const StringHelper = {
    camelCaseToDash,
    decimalSeparatorReplace,
    filterByTerm,
    slugify,
};
