import { InMemoryCache, NormalizedCacheObject } from "@apollo/client/cache";
import { FieldMergeFunction } from "@apollo/client/cache/inmemory/policies";
import { ApolloClient, ApolloLink, split } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { HttpLink } from "@apollo/client/link/http";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { config } from "@config/config";
import cursorPagination from "@lib/cursorPagination";
import { isBrowser } from "@uxf_base/utils/isBrowser";
import { createClient } from "graphql-ws";
import fetch from "isomorphic-unfetch";

let apolloClient: ApolloClient<any> | null = null;

// Polyfill fetch() on the server (used by apollo-client)
if (!isBrowser) {
    // eslint-disable-next-line
    // @ts-ignore: Polyfill fetch() on the server (used by apollo-client)
    global.fetch = fetch;
}

function create(initialState: NormalizedCacheObject, options: any) {
    const httpLink = new HttpLink({
        uri: `${config.FRONTEND_URL}/graphql`, // Server URL (must be absolute)
        credentials: "same-origin",
    });

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers, // TODO @vejvis - headers jsou asi vždy undefined ale bojím se to smazat :-)
                cookie: options.req?.headers?.cookie,
                "X-Source": isBrowser ? "browser" : "server",
            },
        };
    });

    const logoutLink = onError((error) => {
        if (
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions?.code === "UNAUTHENTICATED"
        ) {
            options.redirectToLogin();
        }
    });

    const link = isBrowser
        ? split(
              // split based on operation type
              ({ query }) => {
                  const definition = getMainDefinition(query);
                  return definition.kind === "OperationDefinition" && definition.operation === "subscription";
              },
              new GraphQLWsLink(
                  createClient({
                      url: `${config.FRONTEND_WS_URL}/graphql`,
                  }),
              ),
              httpLink,
          )
        : httpLink;

    const useIncoming: FieldMergeFunction<any, any> | boolean = (existing, incoming) => incoming;
    const cache = new InMemoryCache({
        possibleTypes: {
            Address: [
                "ProfileAddress",
                "ClubAddress",
                "ServiceCompanyAddress",
                "EventAddress",
                "MarketAddress",
                "BaseAddress",
            ],
        },
        typePolicies: {
            Query: {
                fields: {
                    clubWall: { keyArgs: ["clubId"], merge: cursorPagination },
                    clubActivities: { keyArgs: ["clubId"], merge: cursorPagination },
                },
            },
            WallPost: {
                fields: {
                    events: {
                        merge: useIncoming,
                    },
                    photos: {
                        merge: useIncoming,
                    },
                    documents: {
                        merge: useIncoming,
                    },
                    surveys: {
                        merge: useIncoming,
                    },
                },
            },
            AnasoftBankAccountTransactions: {
                keyFields: ["id", "year"],
            },
        },
    }).restore(initialState || {});
    // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
    return new ApolloClient({
        connectToDevTools: isBrowser,
        ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
        link: ApolloLink.from([authLink, logoutLink, link]),
        cache,
        defaultOptions: {
            query: {
                errorPolicy: "all",
            },
            watchQuery: {
                errorPolicy: "all",
            },
            mutate: {
                errorPolicy: "none",
            },
        },
    });
}

export default function initApollo(
    initialApolloState: NormalizedCacheObject,
    options?: any,
): ApolloClient<NormalizedCacheObject> {
    // Make sure to create a new client for every server-side request so that data
    // isn't shared between connections (which would be bad)
    if (!isBrowser) {
        return create(initialApolloState, options);
    }

    // Reuse client on the client-side
    if (!apolloClient) {
        apolloClient = create(initialApolloState, options);
    }

    return apolloClient;
}
