import { css, FlattenSimpleInterpolation } from "styled-components";

export const buttonResetMixin = css`
    appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
`;

export const srOnlyMixin = css`
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    text-indent: -9999px;
`;

export const noWrapMixin = css`
    white-space: nowrap;
`;

export const truncateMixin = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const multilineTruncateMixin = (lines: number): FlattenSimpleInterpolation => css`
    /* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* stylelint-enable */
`;

export const inputResetMixin = css`
    ${buttonResetMixin};

    /* stylelint-disable property-no-vendor-prefix, no-descending-specificity */
    &:invalid {
        box-shadow: none;
    }

    &::-moz-placeholder {
        opacity: 1;
    }

    &::-ms-clear,
    &::-ms-reveal {
        display: none;
    }

    &::-webkit-calendar-picker-indicator,
    &::-webkit-clear-button,
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type="date"],
    &[type="datetime"],
    &[type="datetime-local"],
    &[type="month"],
    &[type="number"],
    &[type="time"],
    &[type="week"] {
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }
    /* stylelint-enable */
`;

export const selectResetMixin = (fontFamily: string): FlattenSimpleInterpolation => css`
    ${buttonResetMixin};

    /* stylelint-disable property-no-vendor-prefix, no-descending-specificity */
    &:invalid {
        box-shadow: none;
    }

    &::-ms-expand {
        display: none;
    }
    /* stylelint-enable */

    option {
        font-family: ${fontFamily};
        font-size: 0.8rem;
        font-weight: 400;
    }
`;

export const textareaResetMixin = css`
    ${buttonResetMixin};

    /* stylelint-disable property-no-vendor-prefix, no-descending-specificity */
    &:invalid {
        box-shadow: none;
    }

    &::-moz-placeholder {
        opacity: 1;
    }
    /* stylelint-enable */
`;

export const inputAutofillMixin = (background: string, color: string): FlattenSimpleInterpolation => css`
    /* stylelint-disable property-no-vendor-prefix, no-descending-specificity */
    &:-webkit-autofill {
        -webkit-text-fill-color: ${color} !important;
        -webkit-box-shadow: 0 0 0 1000px ${background} inset;
    }
    &:-moz-autofill {
        -moz-text-fill-color: ${color} !important;
        -moz-box-shadow: 0 0 0 1000px ${background} inset;
    }
    &:-o-autofill {
        -o-text-fill-color: ${color} !important;
        -o-box-shadow: 0 0 0 1000px ${background} inset;
    }
    &:-khtml-autofill {
        -khtml-text-fill-color: ${color} !important;
        -khtml-box-shadow: 0 0 0 1000px ${background} inset;
    }
    /* stylelint-enable */
`;
