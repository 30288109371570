import { IconSizeEnum } from "@config/icons";
import { FOCUS_VISIBLE_CLASSNAME, IS_HOVERABLE_CLASSNAME } from "@styles/constants";
import { truncate } from "@styles/mixins";
import { BasicColorVariant, ButtonKind, ITheme, Palette } from "@styles/theme";
import { Clickable } from "@ui/Clickable";
import { CssHelper } from "@utils/CssHelper";
import { withUnit } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { IRootProps } from "./types";

const getKind = (
    backgroundColor: Palette,
    kind: ButtonKind,
    shadow: boolean,
    variant: BasicColorVariant,
    theme: ITheme,
) => {
    const defaultStyle = css`
        & > .inner-component {
            background-color: ${theme.color.button.default.background(variant)[0]};
            box-shadow: ${shadow ? theme.shadow.button(variant) : null};
            color: ${variant === "tertiary" ? theme.color.palette.text : theme.color.button.default.text};
            transition: ${CssHelper.transition("backgroundColor")};
        }

        &.${IS_HOVERABLE_CLASSNAME} {
            &:hover,
            &:active {
                & > .inner-component {
                    background-color: ${theme.color.button.default.background(variant)[1]};
                    color: ${variant === "tertiary" ? theme.color.palette.text : theme.color.button.default.text};
                }
            }
        }
    `;

    const mutedStyle = css`
        & > .inner-component {
            background-color: ${theme.color.button.muted.background(variant)[0]};
            box-shadow: ${shadow ? theme.shadow.button(variant) : null};
            color: ${variant === "tertiary" ? theme.color.palette.text : theme.color.button.muted.text(variant)};
            transition: ${CssHelper.transition("backgroundColor")};
        }

        &.${IS_HOVERABLE_CLASSNAME} {
            &:hover,
            &:active {
                & > .inner-component {
                    background-color: ${theme.color.button.muted.background(variant)[1]};
                    color: ${variant === "tertiary"
                        ? theme.color.palette.text
                        : theme.color.button.muted.text(variant)};
                }
            }
        }
    `;

    const textStyle = css`
        & > .inner-component {
            background-color: ${backgroundColor ? theme.color.palette[backgroundColor] : null};
            color: ${variant === "tertiary" ? theme.color.palette.text : theme.color.button.text.text(variant)[0]};
            transition: ${CssHelper.transition("color")};
        }

        &.${IS_HOVERABLE_CLASSNAME} {
            &:hover,
            &:active {
                & > .inner-component {
                    color: ${variant === "tertiary"
                        ? theme.color.palette.text
                        : theme.color.button.text.text(variant)[1]};
                }
            }
        }
    `;

    const whiteStyle = css`
        & > .inner-component {
            background-color: white;
            border: ${withUnit(theme.border.default, "px")} solid ${theme.color.palette.border};
            color: ${theme.color.button.white.text(variant)[0]};
            transition: ${CssHelper.transition("color")};
        }

        &.${IS_HOVERABLE_CLASSNAME} {
            &:hover,
            &:active {
                & > .inner-component {
                    color: ${variant === "tertiary"
                        ? theme.color.palette.text
                        : theme.color.button.white.text(variant)[1]};
                }
            }
        }
    `;

    const transparentStyle = css`
        & > .inner-component {
            background-color: ${theme.color.button.transparent.background[0]};
            border: ${withUnit(theme.border.thick, "px")} solid ${theme.color.palette.white};
            color: ${theme.color.button.transparent.text[0]};
            transition: ${CssHelper.transition("color")}, ${CssHelper.transition("border")},
                ${CssHelper.transition("backgroundColor")};
        }

        &.${IS_HOVERABLE_CLASSNAME} {
            &:hover,
            &:active {
                & > .inner-component {
                    background-color: ${theme.color.button.transparent.background[1]};
                    border: ${withUnit(theme.border.thick, "px")} solid ${theme.color.palette.inputBorder};
                    color: ${theme.color.button.transparent.text[1]};
                }
            }
        }
    `;

    const outlineStyle = css`
        & > .inner-component {
            background-color: ${theme.color.button.transparent.background[0]};
            border: ${withUnit(theme.border.thick, "px")} solid ${theme.color.button.outline.text(variant)[0]};
            color: ${theme.color.button.outline.text(variant)[0]};
            transition: ${CssHelper.transition("color")}, ${CssHelper.transition("borderColor")},
                ${CssHelper.transition("backgroundColor")};
        }

        &.${IS_HOVERABLE_CLASSNAME} {
            &:hover,
            &:active {
                & > .inner-component {
                    background-color: ${theme.color.button.outline.background(variant)[1]};
                    border-color: ${theme.color.button.outline.text(variant)[1]};
                    color: ${theme.color.button.outline.text(variant)[1]};
                }
            }
        }
    `;

    switch (kind) {
        case "muted":
            return mutedStyle;
        case "text":
            return textStyle;
        case "white":
            return whiteStyle;
        case "transparent":
            return transparentStyle;
        case "outline":
            return outlineStyle;
        default:
            return defaultStyle;
    }
};

export const Root = styled(Clickable)<IRootProps>(({
    backgroundColor,
    kind,
    pl,
    pr,
    shadow,
    size,
    variant,
    width,
    theme,
}) => {
    const fontSize =
        size === "tiny"
            ? rem(theme.typography.variant.caption.fontSize)
            : size === "small"
              ? rem(theme.typography.variant.medium.fontSize)
              : rem(theme.typography.variant.large.fontSize);

    return css`
        && {
            border-radius: ${rem(theme.borderRadius.default)};
            cursor: pointer;
            display: inline-block;
            flex: 0 0 auto;
            font-size: ${fontSize};
            font-weight: ${theme.typography.fontWeight.medium};
            height: ${size ? rem(theme.size.button[size]) : null};
            letter-spacing: ${theme.typography.letterSpacing};
            line-height: ${size === "small"
                ? rem(theme.typography.variant.medium.lineHeight)
                : rem(theme.typography.variant.large.lineHeight)};
            max-width: 100%;
            position: relative;
            width: ${width ? rem(width) : null};

            :disabled {
                cursor: not-allowed;
            }

            ${getKind(
                backgroundColor ?? "transparent",
                kind ?? "default",
                shadow ?? false,
                variant ?? "primary",
                theme,
            )}

            & > .inner-component {
                align-items: center;
                border-radius: inherit;
                display: flex;
                height: inherit;
                justify-content: center;
                padding-bottom: ${rem(theme.legacySpacing(0.5))};
                padding-left: ${CssHelper.value(pl ?? theme.legacySpacing(1))};
                padding-right: ${CssHelper.value(pr ?? theme.legacySpacing(1))};
                padding-top: ${rem(theme.legacySpacing(0.5))};
                position: relative;

                & > .start-icon {
                    flex: 0 0 auto;
                    margin-right: ${rem(theme.legacySpacing(1.5))};
                }

                & > .start-component {
                    display: inline-block;
                    flex: 0 0 auto;
                    margin-right: ${rem(theme.legacySpacing(1.5))};
                }

                & > .end-component {
                    display: inline-block;
                    flex: 0 0 auto;
                    margin-left: ${rem(theme.legacySpacing(1.5))};
                }

                & > .end-icon {
                    flex: 0 0 auto;
                    margin-left: auto;
                }

                & > .text {
                    display: inline-block;
                    flex: 0 1 auto;
                    min-width: 0;
                    ${truncate};

                    &:first-child:not(:last-child) {
                        margin-left: auto;
                        padding-left: ${rem(IconSizeEnum.large)};
                    }
                }
            }

            &.${FOCUS_VISIBLE_CLASSNAME} {
                box-shadow: ${theme.shadow.buttonFocus};
                z-index: 1;
            }
        }
    `;
});
