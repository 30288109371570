import { createContext, useContext } from "react";

type WebPushContextType = {
    isReady: boolean;
    isSubscribed: boolean;
    subscribe: () => void;
    unsubscribe: () => void;
};

export const WebPushContext = createContext<WebPushContextType>({
    isReady: false,
    isSubscribed: false,
    subscribe: () => null,
    unsubscribe: () => null,
});

export function useWebPush() {
    return useContext(WebPushContext);
}
