import { Modal } from "@ui/Modal/Modal";
import { ModalProps } from "@ui/Modal/types";
import { useBodyScrollLock } from "@uxf/core/hooks/useBodyScrollLock";
import { FC, ReactNode, useCallback, useRef, useState } from "react";
import { createPortal } from "react-dom";

export const useModal = (): [(modalComponent?: ReactNode) => void, FC<ModalProps>, () => void] => {
    const modalRootRef = useRef<HTMLDivElement>(null);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);

    useBodyScrollLock<HTMLDivElement>(modalRootRef, !!modalContent);

    const closeModal = useCallback(() => {
        setModalContent(null);
    }, []);

    const ModalProvider: FC<ModalProps> = useCallback(
        (props) => {
            return modalContent
                ? createPortal(
                      <Modal handleClose={closeModal} ref={modalRootRef} {...props}>
                          {modalContent}
                      </Modal>,
                      document.body,
                  )
                : null;
        },
        [closeModal, modalContent, modalRootRef],
    );

    return [setModalContent, ModalProvider, closeModal];
};
