import { createLocalize } from "@uxf/localize";
import cs from "@uxf/localize/locale/cs";
import en from "@uxf/localize/locale/en";
import sk from "@uxf/localize/locale/sk";

export const {
    LocalizeProvider,
    useFormatMoney,
    useFormatDateTime,
    useFormatNumber,
    useFormatPercentage,
    FormatNumber,
    FormatDateTime,
    FormatMoney,
    FormatPercentage,
} = createLocalize({ cs, sk, en });
