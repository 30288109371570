import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { Button } from "@ui/Button";
import { ModalContent } from "@ui/ModalContent";
import { TextH4, TextMedium } from "@ui/Text";

interface WebPushModalProps {
    onAccept: () => void;
    onReject: () => void;
}

export function WebPushModal(props: WebPushModalProps) {
    const { t } = useTranslation();
    return (
        <ModalContent
            buttons={
                <>
                    <Button kind="text" variant="textMuted" onClick={props.onReject}>
                        {t("web-push:modal.no")}
                    </Button>
                    <Button variant="secondary" onClick={props.onAccept}>
                        {t("web-push:modal.yes")}
                    </Button>
                </>
            }
            contentProps={{ pb: 40 }}
        >
            <Box pl={16} pr={16}>
                <TextH4 mr={32} mb={24}>
                    {t("web-push:modal.title")}
                </TextH4>
                <TextMedium>{t("web-push:modal.description")}</TextMedium>
            </Box>
        </ModalContent>
    );
}
