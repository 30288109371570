
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { ApolloProvider } from "@apollo/client/react/context";
import { LoggedUserServices } from "@components/Core/LoggedUserServices";
import { config } from "@config/config";
import { ICONS } from "@config/icons-new/icons";
import { ICONS_VERSION, ICON_SPRITE } from "@config/icons-new/icons-config";
import { twScreens } from "@config/tw-tokens/tw-screens";
import { UAParserContext } from "@lib/UAParserContext";
import withApollo from "@lib/withApollo";
import { LocalizeProvider } from "@localize";
import * as Sentry from "@sentry/nextjs";
import { getFlashMessagesRef } from "@services/FlashMessagesService";
import { RedirectError } from "@shared/error/RedirectError";
import { RedirectToUrl } from "@shared/error/RedirectToUrl";
import { WebPushProvider } from "@shared/web-push/web-push-provider";
import "@styles/globals.css";
import { theme } from "@styles/theme";
import { FlashMessages } from "@ui/FlashMessages";
import { TokenInvitationHelper } from "@utils/TokenInvitationHelper";
import { UrlHelper } from "@utils/UrlHelper";
import { COLOR_SCHEME_DEFAULTS } from "@uxf/ui/color-scheme";
import { UiContextProvider, UiContextType } from "@uxf/ui/context";
import withReduxStore from "@uxf_base/libs/with-redux-store";
import { getParser } from "bowser";
import "core-js/features/object/from-entries";
import { locale as dayjsLocale } from "dayjs";
import "dayjs/locale/cs";
import "dayjs/locale/en";
import "dayjs/locale/sk";
import "focus-visible";
import LogRocket from "logrocket";
import { locale as momentLocale } from "moment";
import "moment/locale/cs";
import "moment/locale/en-gb";
import "moment/locale/sk";
import useTranslation from "next-translate/useTranslation"; // eslint-disable-line no-restricted-imports
import { AppContext } from "next/app";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
if (config.SENTRY_DSN) {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.STAGE,
    });
}
if (config.LOGROCKET_ID) {
    LogRocket.init(config.LOGROCKET_ID);
}
const UI_PROVIDER_CONFIG: UiContextType = {
    colorScheme: {
        defaultColorScheme: "light",
        getUserSettings: COLOR_SCHEME_DEFAULTS.getUserSettings,
        respectSystemSetting: false,
        respectUserSettings: false,
        setUserSettings: COLOR_SCHEME_DEFAULTS.setUserSettings,
    },
    domain: config.FRONTEND_URL ?? "",
    icon: { spriteFilePath: `${ICON_SPRITE}?v=${ICONS_VERSION}`, iconsConfig: ICONS },
    rasterImage: { breakpoints: twScreens },
};
function UxfApp(props: any) {
    const { Component, pageProps, reduxStore, apolloClient, uaParsed } = props;
    const { lang } = useTranslation();
    momentLocale(lang);
    dayjsLocale(lang);
    return (<LocalizeProvider value={lang}>
            <UAParserContext.Provider value={uaParsed}>
                <ApolloProvider client={apolloClient}>
                    <Provider store={reduxStore}>
                        <ThemeProvider theme={theme}>
                            <UiContextProvider value={UI_PROVIDER_CONFIG}>
                                <WebPushProvider>
                                    <Component {...pageProps}/>
                                </WebPushProvider>
                                <FlashMessages ref={getFlashMessagesRef()}/>
                                <LoggedUserServices />
                            </UiContextProvider>
                        </ThemeProvider>
                    </Provider>
                </ApolloProvider>
            </UAParserContext.Provider>
        </LocalizeProvider>);
}
UxfApp.getInitialProps = async (appCtx: AppContext): Promise<any> => {
    let pageProps = {};
    try {
        await TokenInvitationHelper.handleTokenInvitation(appCtx.ctx, (appCtx.ctx as any).apolloClient);
        if (appCtx.Component.getInitialProps) {
            pageProps = await appCtx.Component.getInitialProps(appCtx.ctx);
        }
        const ua = appCtx.ctx.req?.headers["user-agent"] ?? ((typeof navigator !== "undefined" && navigator.userAgent) || "");
        const uaParsed = getParser(ua).getResult();
        return { uaParsed, pageProps };
    }
    catch (e) {
        if (e instanceof RedirectError) {
            await UrlHelper.redirectRoute(appCtx.ctx, e.route, e.params);
            return { pageProps };
        }
        if (e instanceof RedirectToUrl) {
            await UrlHelper.redirectUrl(appCtx.ctx, e.url);
            return { pageProps };
        }
        throw e;
    }
};
const __Next_Translate__Page__1932a0f7034__ = withApollo(withReduxStore(UxfApp));

    export default __appWithI18n(__Next_Translate__Page__1932a0f7034__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  