import {
    marginMixin,
    paddingMixin,
    responsiveHiddenMixin,
    ResponsiveHiddenMixinProps,
    responsiveMultilineTruncateMixin,
    ResponsiveMultilineTruncateMixinProps,
    responsiveNoWrapMixin,
    ResponsiveNoWrapMixinProps,
    responsiveSrOnlyMixin,
    ResponsiveSrOnlyMixinProps,
    responsiveTruncateMixin,
    ResponsiveTruncateMixinProps,
} from "@styles/newMixins";
import { theme } from "@styles/theme";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import styled, { css } from "styled-components";
import { ITextProps, LineBreakProps } from "./types";

export const Text = styled("p")<ITextProps>(
    ({
        maxLines,
        mb,
        ml,
        mr,
        mt,
        noWrap,
        pb,
        permanentlyHidden,
        pl,
        pr,
        pt,
        textAlign,
        textColor,
        textDecoration,
        textFontSize,
        textFontStyle,
        textFontWeight,
        textLetterSpacing = theme.typography.letterSpacing,
        textLineHeight,
        textVariant,
        truncate,
        visuallyHidden,
    }) => css`
        color: ${textColor ? null : "inherit"};
        font-size: ${textFontSize || textVariant ? null : "inherit"};
        font-style: ${textFontStyle ? null : "inherit"};
        font-weight: ${textFontWeight ? null : "inherit"};
        letter-spacing: ${textLetterSpacing ? null : "inherit"};
        line-height: ${textLineHeight || textVariant ? null : "inherit"};
        overflow-wrap: break-word;
        word-break: break-word;

        ${Responsive.facePaintedMin({
            fontSize: CssHelper.themeTextVariantFontSizeValue(textVariant),
            lineHeight: CssHelper.themeTextVariantLineHeightValue(textVariant),
        })}

        ${Responsive.facePaintedMin({
            color: CssHelper.themePaletteValue(textColor),
            fontSize: CssHelper.value(textFontSize),
            fontStyle: CssHelper.value(textFontStyle),
            fontWeight: CssHelper.themeFontWeightValue(textFontWeight),
            letterSpacing: CssHelper.value(textLetterSpacing),
            lineHeight: CssHelper.value(textLineHeight),
            textAlign: CssHelper.value(textAlign),
            textDecoration: CssHelper.value(textDecoration),
        })}

        ${marginMixin({ mb, ml, mr, mt })};
        ${paddingMixin({ pb, pl, pr, pt })};

        ${typeof maxLines === "object"
            ? responsiveMultilineTruncateMixin(maxLines as ResponsiveMultilineTruncateMixinProps)
            : maxLines
              ? responsiveMultilineTruncateMixin({ lines: maxLines })
              : null};
        ${typeof truncate === "object"
            ? responsiveTruncateMixin(truncate as ResponsiveTruncateMixinProps)
            : truncate
              ? responsiveTruncateMixin({ condition: truncate })
              : null};
        ${typeof noWrap === "object"
            ? responsiveNoWrapMixin(noWrap as ResponsiveNoWrapMixinProps)
            : noWrap
              ? responsiveNoWrapMixin({ condition: noWrap })
              : null};

        && {
            ${typeof visuallyHidden === "object"
                ? responsiveSrOnlyMixin(visuallyHidden as ResponsiveSrOnlyMixinProps)
                : visuallyHidden
                  ? responsiveSrOnlyMixin({ condition: visuallyHidden })
                  : null};

            ${typeof permanentlyHidden === "object"
                ? responsiveHiddenMixin(permanentlyHidden as ResponsiveHiddenMixinProps)
                : permanentlyHidden
                  ? responsiveHiddenMixin({ condition: permanentlyHidden })
                  : null};
        }
    `,
);

Text.defaultProps = {
    // textFontSize: "inherit",
    // textLineHeight: "inherit",
};

export const TextH1 = styled(Text)<ITextProps>``;

TextH1.defaultProps = {
    as: "h1",
    textVariant: "h1",
    textFontWeight: "medium",
};

export const TextH2 = styled(Text)<ITextProps>``;

TextH2.defaultProps = {
    textVariant: "h2",
    textFontWeight: "medium",
};

export const TextH3 = styled(Text)<ITextProps>``;

TextH3.defaultProps = {
    textVariant: "h3",
    textFontWeight: "medium",
};

export const TextH4 = styled(Text)<ITextProps>``;

TextH4.defaultProps = {
    textVariant: "h4",
    textFontWeight: "medium",
};

export const TextH5 = styled(Text)<ITextProps>``;

TextH5.defaultProps = {
    textVariant: "h5",
    textFontWeight: "medium",
};

export const TextLarge = styled(Text)<ITextProps>``;

TextLarge.defaultProps = {
    textVariant: "large",
};

export const TextMedium = styled(Text)<ITextProps>``;

TextMedium.defaultProps = {
    textVariant: "medium",
};

export const TextSmall = styled(Text)<ITextProps>``;

TextSmall.defaultProps = {
    textVariant: "small",
};

export const TextCaption = styled(Text)<ITextProps>``;

TextCaption.defaultProps = {
    textVariant: "caption",
};

export const TextTiny = styled(Text)<ITextProps>``;

TextTiny.defaultProps = {
    textVariant: "tiny",
};

export const Strong = styled(Text)<ITextProps>``;

Strong.defaultProps = {
    as: "strong",
    textFontWeight: "semiBold",
};

export const Em = styled(Text)<ITextProps>``;

Em.defaultProps = {
    as: "em",
};

export const Span = styled(Text)<ITextProps>``;

Span.defaultProps = {
    as: "span",
};

export const NoWrap = styled(Text)<ITextProps>``;

NoWrap.defaultProps = {
    as: "span",
    noWrap: true,
};

export const Truncate = styled(Text)<ITextProps>``;

Truncate.defaultProps = {
    as: "span",
    truncate: true,
};

export const LineBreak = styled("br")<LineBreakProps>(
    ({ boxDisplay, permanentlyHidden }) => css`
        ${Responsive.facePaintedMin({ display: CssHelper.value(boxDisplay) })};

        && {
            ${typeof permanentlyHidden === "object"
                ? responsiveHiddenMixin(permanentlyHidden as ResponsiveHiddenMixinProps)
                : permanentlyHidden
                  ? responsiveHiddenMixin({ condition: permanentlyHidden })
                  : null};
        }
    `,
);
