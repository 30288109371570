import { BooleanType, NumberType, ResponsiveRange, StringNumberType } from "@app-types/css";
import { theme } from "@styles/theme";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import { FlattenSimpleInterpolation, css } from "styled-components";
import { multilineTruncateMixin, noWrapMixin, srOnlyMixin, truncateMixin } from "./newBaseMixins";

export type Margin = "auto" | StringNumberType | Array<"auto" | StringNumberType>;

export interface MarginProps {
    mb?: Margin;
    ml?: Margin;
    mr?: Margin;
    mt?: Margin;
}

export const marginMixin = ({ mb, ml, mr, mt }: MarginProps): FlattenSimpleInterpolation => css`
    ${Responsive.facePaintedMin({
        marginBottom: CssHelper.value(mb),
        marginLeft: CssHelper.value(ml),
        marginRight: CssHelper.value(mr),
        marginTop: CssHelper.value(mt),
    })}
`;

export type Padding = StringNumberType | StringNumberType[];

export interface PaddingProps {
    pb?: Padding;
    pl?: Padding;
    pr?: Padding;
    pt?: Padding;
}

export const paddingMixin = ({ pb, pl, pr, pt }: PaddingProps): FlattenSimpleInterpolation => css`
    ${Responsive.facePaintedMin({
        paddingBottom: CssHelper.value(pb),
        paddingLeft: CssHelper.value(pl),
        paddingRight: CssHelper.value(pr),
        paddingTop: CssHelper.value(pt),
    })}
`;

export interface ResponsiveSrOnlyMixinProps extends ResponsiveRange {
    condition: BooleanType;
}

export const responsiveSrOnlyMixin = (props: ResponsiveSrOnlyMixinProps) => {
    const { condition, from, to } = props;
    if (from && to && condition) {
        return css`
            ${Responsive.between(theme.breakpoint[from], theme.breakpoint[to])} {
                ${srOnlyMixin};
            }
        `;
    }
    if (from && condition) {
        return css`
            ${Responsive.min(theme.breakpoint[from])} {
                ${srOnlyMixin};
            }
        `;
    }
    if (to && condition) {
        return css`
            ${Responsive.max(theme.breakpoint[to])} {
                ${srOnlyMixin};
            }
        `;
    }
    return condition ? srOnlyMixin : null;
};

export interface ResponsiveHiddenMixinProps extends ResponsiveRange {
    condition: BooleanType;
}

export const responsiveHiddenMixin = (props: ResponsiveHiddenMixinProps) => {
    const { condition, from, to } = props;
    if (from && to && condition) {
        return css`
            ${Responsive.between(theme.breakpoint[from], theme.breakpoint[to])} {
                display: none !important;
            }
        `;
    }
    if (from && condition) {
        return css`
            ${Responsive.min(theme.breakpoint[from])} {
                display: none !important;
            }
        `;
    }
    if (to && condition) {
        return css`
            ${Responsive.max(theme.breakpoint[to])} {
                display: none !important;
            }
        `;
    }
    return condition
        ? css`
              display: none !important;
          `
        : null;
};

export interface ResponsiveTruncateMixinProps extends ResponsiveRange {
    condition: BooleanType;
}

export const responsiveTruncateMixin = (props: ResponsiveTruncateMixinProps) => {
    const { condition, from, to } = props;
    if (from && to && condition) {
        return css`
            ${Responsive.between(theme.breakpoint[from], theme.breakpoint[to])} {
                overflow-wrap: break-word;
                word-break: normal;
                ${truncateMixin};
            }
        `;
    }
    if (from && condition) {
        return css`
            ${Responsive.min(theme.breakpoint[from])} {
                overflow-wrap: break-word;
                word-break: normal;
                ${truncateMixin};
            }
        `;
    }
    if (to && condition) {
        return css`
            ${Responsive.max(theme.breakpoint[to])} {
                overflow-wrap: break-word;
                word-break: normal;
                ${truncateMixin};
            }
        `;
    }
    return condition
        ? css`
              overflow-wrap: break-word;
              word-break: normal;
              ${truncateMixin}
          `
        : null;
};

export interface ResponsiveMultilineTruncateMixinProps extends ResponsiveRange {
    lines: NumberType;
}

export const responsiveMultilineTruncateMixin = (props: ResponsiveMultilineTruncateMixinProps) => {
    const { lines, from, to } = props;
    if (from && to && lines) {
        return css`
            ${Responsive.between(theme.breakpoint[from], theme.breakpoint[to])} {
                ${multilineTruncateMixin(lines)};
            }
        `;
    }
    if (from && lines) {
        return css`
            ${Responsive.min(theme.breakpoint[from])} {
                ${multilineTruncateMixin(lines)};
            }
        `;
    }
    if (to && lines) {
        return css`
            ${Responsive.max(theme.breakpoint[to])} {
                ${multilineTruncateMixin(lines)};
            }
        `;
    }
    return lines ? multilineTruncateMixin(lines as number) : null;
};

export interface ResponsiveNoWrapMixinProps extends ResponsiveRange {
    condition: BooleanType;
}

export const responsiveNoWrapMixin = (props: ResponsiveNoWrapMixinProps) => {
    const { condition, from, to } = props;
    if (from && to && condition) {
        return css`
            ${Responsive.between(theme.breakpoint[from], theme.breakpoint[to])} {
                ${noWrapMixin};
            }
        `;
    }
    if (from && condition) {
        return css`
            ${Responsive.min(theme.breakpoint[from])} {
                ${noWrapMixin};
            }
        `;
    }
    if (to && condition) {
        return css`
            ${Responsive.max(theme.breakpoint[to])} {
                ${noWrapMixin};
            }
        `;
    }
    return condition ? noWrapMixin : null;
};
