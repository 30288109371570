import { useEffect, useRef } from "react";

/** @deprecated Please use `useUpdateEffect` from `@uxf/core/hooks/useUpdateEffect` instead. */
export function useDidUpdateEffect(fn: () => void, deps: any[]) {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            fn();
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
