import { IS_HOVERABLE_CLASSNAME } from "@styles/constants";
import { composeRefs } from "@utils/composeRefs";
import clsx from "clsx";
import Link from "next/link";
import {
    cloneElement,
    forwardRef,
    ForwardRefRenderFunction,
    MouseEventHandler,
    useCallback,
    useEffect,
    useRef,
} from "react";
import { ButtonLink, DummyLink, ExternalLink } from "./styles";
import { IClickableProps } from "./types";

const ClickableRenderFn: ForwardRefRenderFunction<any, IClickableProps> = (props, ref) => {
    const innerRef = useRef<any>(null);
    const {
        analyticsCallback,
        className,
        children,
        disabled,
        dummyLinkElement,
        fakeButton,
        hash,
        href,
        innerComponent = "span",
        innerComponentProps,
        loaderComponent = null,
        locale,
        loading,
        onClick,
        target,
        type,
        tabIndex = 0,
        title,
        ...restProps
    } = props;

    const InnerComponent = innerComponent;

    const loader = !!loading && loaderComponent && cloneElement(loaderComponent, { className: "loader-component" });

    const getTabIndex = disabled || loading ? -1 : tabIndex;
    const getClassName = clsx(className, IS_HOVERABLE_CLASSNAME);
    const FAKE_BUTTON_CLASSNAME = "fake-button";

    const _onClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            if (loading) {
                e.preventDefault();
            } else {
                if (analyticsCallback) {
                    analyticsCallback();
                }
                if (onClick) {
                    onClick(e);
                }
            }
        },
        [analyticsCallback, loading, onClick],
    );

    const onClickHandler = (e: any) => {
        if ((e.keyCode === 13 || e.keyCode === 32) && e.target.classList.contains(FAKE_BUTTON_CLASSNAME)) {
            e.stopPropagation();
            e.target.click();
        }
    };

    useEffect(() => {
        if (innerRef.current) {
            innerRef.current.addEventListener("keyup", onClickHandler);
        }
        return () => {
            if (innerRef.current) {
                // TODO!!! tady by neměl být ignore
                // eslint-disable-next-line react-hooks/exhaustive-deps
                innerRef.current.removeEventListener("keyup", onClickHandler);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (href) {
        if (typeof href === "string" && (href.startsWith("http") || href.startsWith("www"))) {
            return (
                <ExternalLink
                    aria-busy={loading}
                    aria-disabled={disabled}
                    className={getClassName}
                    href={href}
                    onClick={_onClick}
                    ref={ref}
                    tabIndex={getTabIndex}
                    target={target}
                    title={title}
                    {...restProps}
                >
                    <InnerComponent className="inner-component" {...innerComponentProps}>
                        {loader}
                        {children}
                    </InnerComponent>
                </ExternalLink>
            );
        }
        return (
            <Link legacyBehavior href={href + (hash ?? "")} locale={locale} passHref target={target} title={title}>
                <ExternalLink
                    aria-busy={loading}
                    aria-disabled={disabled}
                    className={getClassName}
                    ref={ref}
                    tabIndex={getTabIndex}
                    target={target}
                    {...restProps}
                >
                    <InnerComponent className="inner-component" onClick={_onClick} {...innerComponentProps}>
                        {children}
                        {loader}
                    </InnerComponent>
                </ExternalLink>
            </Link>
        );
    }

    return onClick ? (
        fakeButton ? (
            <DummyLink
                aria-busy={loading}
                aria-disabled={disabled}
                as={dummyLinkElement}
                className={clsx(getClassName, FAKE_BUTTON_CLASSNAME)}
                onClick={_onClick}
                ref={composeRefs(innerRef, ref)}
                role="button"
                tabIndex={disabled || loading ? -1 : 0}
                title={title}
                {...restProps}
            >
                <InnerComponent className="inner-component" {...innerComponentProps}>
                    {loader}
                    {children}
                </InnerComponent>
            </DummyLink>
        ) : (
            <ButtonLink
                aria-busy={loading}
                className={getClassName}
                disabled={disabled}
                onClick={_onClick}
                ref={ref}
                tabIndex={getTabIndex}
                title={title}
                type={type}
                {...restProps}
            >
                <InnerComponent className="inner-component" {...innerComponentProps}>
                    {loader}
                    {children}
                </InnerComponent>
            </ButtonLink>
        )
    ) : (
        <DummyLink
            aria-busy={loading}
            aria-disabled={disabled}
            as={dummyLinkElement}
            className={className}
            ref={ref}
            title={title}
            {...restProps}
        >
            <InnerComponent className="inner-component" {...innerComponentProps}>
                {loader}
                {children}
            </InnerComponent>
        </DummyLink>
    );
};

/** @deprecated Please use `NewClickable` */
export const Clickable = forwardRef<any, IClickableProps>(ClickableRenderFn);

Clickable.defaultProps = {
    type: "button",
};

Clickable.displayName = "Clickable";
