import { Box, IBoxProps } from "@ui/Box";
import { TextMedium } from "@ui/Text";
import { withUnit } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { IRootProps } from "./types";

export const Root = styled("div")<IRootProps>`
    border-radius: inherit;
    width: 100%;
`;

export const Footer = styled("footer")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        border-radius: inherit;
        display: flex;
        height: ${rem(theme.size.button.medium)};
        min-width: 0;
        width: 100%;

        &&& > * {
            border-radius: 0;
            flex: 1 1 auto;
            height: inherit;
            min-width: 50%;
            position: relative;

            & > .inner-component {
                box-shadow: none;
            }

            &:first-child {
                border-bottom-left-radius: inherit;
            }

            &:last-child {
                border-bottom-right-radius: inherit;
            }

            &[data-kind="text"]::before {
                border-top: ${withUnit(theme.border.default, "px")} solid ${theme.color.palette.border};
                content: "";
                display: block;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }
        }
    `,
);

export const Content = styled(Box)<IBoxProps>`
    border-radius: inherit;
    position: relative;
    width: 100%;
`;

export const ModalContentTitle = styled(TextMedium)``;

ModalContentTitle.defaultProps = {
    as: "h2",
    textFontWeight: "medium",
    textColor: "secondary",
};
