import { IconSizeEnum } from "@config/icons";
import { Badge } from "@ui/Badge";
import { Icon } from "@ui/Icon";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { Root } from "./styles";
import { IGhostButtonProps } from "./types";

const GhostButtonRenderFn: ForwardRefRenderFunction<any, IGhostButtonProps> = (props, ref) => {
    const { badge, badgeProps, iconLabel, iconSize = IconSizeEnum.large, name, title, ...restProps } = props;

    return (
        <Root ref={ref} innerComponentProps={{ className: "ghost" }} title={title} {...restProps}>
            <Icon aria-label={iconLabel} className="ghost__icon" name={name} size={iconSize} />
            {!!badge && (
                <Badge position="top" {...badgeProps}>
                    {badge}
                </Badge>
            )}
        </Root>
    );
};

export const GhostButton = forwardRef<any, IGhostButtonProps>(GhostButtonRenderFn);

GhostButton.defaultProps = {
    variant: "textMuted",
};

GhostButton.displayName = "GhostButton";
