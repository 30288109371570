// this file is generated automatically, do not change anything manually in the contents of this file

export const ICONS_VERSION = "c1a83be6e8f83ea18bf93ff870668e85";

export const ICONS = {
    "addUser": [20, 24],
    "apple": [16, 20, 24],
    "arrowToggle": [20, 24],
    "arrowLeft": [16, 20, 24],
    "arrowRight": [16, 20, 24],
    "arrowRightLine": [20, 24],
    "arrowLeftLine": [20, 24],
    "auction": [40],
    "ball": [20, 24],
    "blockOfFlats": [20, 24],
    "bookmark": [20, 24],
    "book": [20, 24],
    "box": [20, 24],
    "calendar": [20, 24],
    "camera": [20, 24],
    "check": [16, 20, 24],
    "comment": [20, 24],
    "contacts": [20, 24],
    "cornerDownRight": [16, 20, 24],
    "cross": [16, 20, 24],
    "danger": [20, 24],
    "database": [24],
    "door": [20, 24],
    "download": [20, 24],
    "error": [20, 24],
    "eye": [16, 20, 24],
    "facebook": [20, 24],
    "file": [20, 24],
    "filePlus": [20, 24],
    "filter": [20, 24],
    "folder": [20, 24],
    "folderPlus": [20, 24],
    "google": [20, 24],
    "helpInverse": [20],
    "hamburger": [24],
    "hammer": [20, 24],
    "hearth": [20, 24],
    "hearthFilled": [20, 24],
    "house": [20, 24],
    "houseAlt": [20, 24],
    "houseAltFilled": [24],
    "info": [20, 24],
    "infoCircle": [20, 24],
    "instagram": [16, 20, 24],
    "leave": [20, 24],
    "key": [20, 24],
    "linkedIn": [16, 20, 24],
    "list": [20, 24],
    "lock": [20, 24],
    "magazine": [20, 24],
    "mail": [20, 24],
    "mailInvite": [20, 24],
    "market": [20, 24],
    "marketingPartner": [20, 24],
    "medal": [20, 24],
    "megaphone": [20, 24],
    "megaphonePlus": [20, 24],
    "merge": [20, 24],
    "minus": [16, 20, 24],
    "mobile": [20, 24],
    "money": [20, 24],
    "more": [20, 24],
    "move": [20, 24],
    "notifications": [20, 24],
    "organization": [20, 24],
    "pair": [20, 24],
    "paperClip": [20, 24],
    "paperPin": [20, 24],
    "parentFolder": [20, 24],
    "pencil": [16, 20, 24],
    "phone": [20, 24],
    "picture": [20, 24],
    "pin": [16, 20, 24],
    "play": [24],
    "plus": [16, 20, 24],
    "poll": [20, 24],
    "qr": [20, 24],
    "question": [20, 24],
    "readAll": [20, 24],
    "refresh": [20, 24],
    "removeUser": [20, 24],
    "rocket": [20, 24],
    "sad": [20, 24],
    "search": [20, 24],
    "sendFilled": [24],
    "settings": [20, 24],
    "share": [20, 24],
    "shield": [20, 24],
    "sort": [16, 20, 24],
    "star": [20, 24],
    "starFilled": [16, 20, 24],
    "surveys": [20, 24],
    "team": [20, 24],
    "time": [16],
    "trash": [20, 24],
    "triangleDown": [20, 24],
    "triangleUp": [20, 24],
    "twitter": [16, 20, 24],
    "user": [16, 20, 24],
    "users": [16, 20, 24],
    "userFilled": [24],
    "usersFilled": [24],
    "url": [20, 24],
    "youTube": [20, 24],
    "wiki": [20, 24],
    "saw": [40],
    "basket": [40],
    "peerToPeer": [40],
    "wallet": [40],
    "property": [40],
    "userWithTextBubble": [40],
    "kiosk": [40],
    "laptop": [40],
    "serviceKey": [20, 24],
    "domus": [18],
    "print": [20, 24],
    "parking": [20, 24],
    "parkingGarage": [20, 24],
    "parkingSign": [20, 24],
    "tikTok": [20, 24],
    "checkeredFlag": [24],
    "flagCZ": [32],
    "flagUK": [32],
    "flagSK": [32],
    "portalPro": [40],
} as const;

export type IconsType = keyof typeof ICONS;

declare module "@uxf/ui/icon/theme" {
    interface IconsSet {
        "addUser": true;
        "apple": true;
        "arrowToggle": true;
        "arrowLeft": true;
        "arrowRight": true;
        "arrowRightLine": true;
        "arrowLeftLine": true;
        "auction": true;
        "ball": true;
        "blockOfFlats": true;
        "bookmark": true;
        "book": true;
        "box": true;
        "calendar": true;
        "camera": true;
        "check": true;
        "comment": true;
        "contacts": true;
        "cornerDownRight": true;
        "cross": true;
        "danger": true;
        "database": true;
        "door": true;
        "download": true;
        "error": true;
        "eye": true;
        "facebook": true;
        "file": true;
        "filePlus": true;
        "filter": true;
        "folder": true;
        "folderPlus": true;
        "google": true;
        "helpInverse": true;
        "hamburger": true;
        "hammer": true;
        "hearth": true;
        "hearthFilled": true;
        "house": true;
        "houseAlt": true;
        "houseAltFilled": true;
        "info": true;
        "infoCircle": true;
        "instagram": true;
        "leave": true;
        "key": true;
        "linkedIn": true;
        "list": true;
        "lock": true;
        "magazine": true;
        "mail": true;
        "mailInvite": true;
        "market": true;
        "marketingPartner": true;
        "medal": true;
        "megaphone": true;
        "megaphonePlus": true;
        "merge": true;
        "minus": true;
        "mobile": true;
        "money": true;
        "more": true;
        "move": true;
        "notifications": true;
        "organization": true;
        "pair": true;
        "paperClip": true;
        "paperPin": true;
        "parentFolder": true;
        "pencil": true;
        "phone": true;
        "picture": true;
        "pin": true;
        "play": true;
        "plus": true;
        "poll": true;
        "qr": true;
        "question": true;
        "readAll": true;
        "refresh": true;
        "removeUser": true;
        "rocket": true;
        "sad": true;
        "search": true;
        "sendFilled": true;
        "settings": true;
        "share": true;
        "shield": true;
        "sort": true;
        "star": true;
        "starFilled": true;
        "surveys": true;
        "team": true;
        "time": true;
        "trash": true;
        "triangleDown": true;
        "triangleUp": true;
        "twitter": true;
        "user": true;
        "users": true;
        "userFilled": true;
        "usersFilled": true;
        "url": true;
        "youTube": true;
        "wiki": true;
        "saw": true;
        "basket": true;
        "peerToPeer": true;
        "wallet": true;
        "property": true;
        "userWithTextBubble": true;
        "kiosk": true;
        "laptop": true;
        "serviceKey": true;
        "domus": true;
        "print": true;
        "parking": true;
        "parkingGarage": true;
        "parkingSign": true;
        "tikTok": true;
        "checkeredFlag": true;
        "flagCZ": true;
        "flagUK": true;
        "flagSK": true;
        "portalPro": true;
    }
}

export enum IconSizeEnum {
    small = 16,
    medium = 20,
    large = 24,
    extraLarge = 40,
    domus = 18,
}

export type IconSize = number | IconSizeEnum;

