import { Box } from "@ui/Box";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import rem from "polished/lib/helpers/rem";
import styled, { css, keyframes } from "styled-components";
import { ILoaderDotProps, IRootProps } from "./types";

const dotTyping = (height: number) => keyframes`
    0% {
        transform: translateY(0);
}
    10% {
        transform: translateY(-${(6 / height) * 100}%);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
    
`;

export const LoaderDot = styled("circle")<ILoaderDotProps>(
    ({ height }) => css`
        animation: ${dotTyping(height)} 1s ease-in-out infinite;

        &:first-child {
            animation-delay: -0.2s;
        }

        &:last-child {
            animation-delay: 0.2s;
        }
    `,
);

export const Root = styled(Box)<IRootProps>(
    ({ absolute, minHeight, boxWidth, theme }) => css`
        align-items: center;
        bottom: ${absolute ? 0 : null};
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        left: ${absolute ? 0 : null};
        overflow: hidden;
        padding-left: ${rem(theme.legacySpacing(0.5))};
        padding-right: ${rem(theme.legacySpacing(0.5))};
        position: ${absolute ? "absolute" : "relative"};
        right: ${absolute ? 0 : null};
        text-align: center;
        top: ${absolute ? 0 : null};

        ${Responsive.facePaintedMin({
            minHeight: absolute ? "none" : CssHelper.value(minHeight),
            width: absolute ? "100%" : CssHelper.value(boxWidth),
        })};
    `,
);
