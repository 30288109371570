import { MutationResult } from "@apollo/client/react";
import * as GQL from "@gql-schema";
import { RequestEmailVerificationMutation, RequestEmailVerificationMutationVariables } from "@gql-schema";
import { ExecutionResult } from "graphql/execution";
import { useCallback } from "react";

// INFO: THIS HOOK IS COMPLETED AND USED
type HookCallback = (
    variables: RequestEmailVerificationMutationVariables,
) => Promise<ExecutionResult<RequestEmailVerificationMutation>>;
type Hook = () => [HookCallback, MutationResult<RequestEmailVerificationMutation>];

export const useRequestEmailVerificationMutation: Hook = () => {
    const [executor, params] = GQL.useRequestEmailVerificationMutation();

    const mutation: HookCallback = useCallback(
        (variables) => {
            return executor({
                variables,
                refetchQueries: [],
            });
        },
        [executor],
    );

    return [mutation, params];
};
